/*tslint:disable*/

import {URLbackend} from "../../common/constants";
import {BaseRequest} from "./BaseRequest";
import {INews} from "./dto/News.g";
import {INewsFilter} from "./dto/NewsFilter.g";
import {IPageResult} from "./dto/PageResult.g";

export class NewsApiRequest extends BaseRequest {
    constructor() {
        super();
        this.baseurl = URLbackend;
    }

    getById(id: string, config?: Object): Promise<INews> {
        return this.fetch(
            `/allApi/admin/News/${id}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    getByPage(pageNumber: number, pageSize: number, config?: Object): Promise<IPageResult<INews>> {
        return this.fetch(
            `/allApi/admin/News/${pageNumber}/${pageSize}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    getByFilter(
        pageNumber: number,
        pageSize: number,
        filter: INewsFilter,
        config?: Object
    ): Promise<IPageResult<INews>> {
        const p: {[key: string]: any} = {};
        p["filter"] = filter;
        return this.fetch(
            `/allApi/admin/News/GetByFilter/${pageNumber}/${pageSize}?${this.getObjectFlatter()(p)}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    publish(id: string, config?: Object): Promise<void> {
        return this.fetch(
            `/allApi/admin/News/${id}/publish`,
            Object.assign(
                {
                    method: "PUT"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    unpublished(id: string, config?: Object): Promise<void> {
        return this.fetch(
            `/allApi/admin/News/${id}/unpublished`,
            Object.assign(
                {
                    method: "PUT"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    delete(id: string, config?: Object): Promise<void> {
        return this.fetch(
            `/allApi/admin/News/${id}`,
            Object.assign(
                {
                    method: "DELETE"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }
}
