import styled from "styled-components";

export const TD = styled.td`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding: 20px 24px;
    cursor: default;
`;
