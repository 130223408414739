import {createSelector} from "reselect";
import {IAppDispatch, IAppState} from "../../core/store/mainReducer";
import {LoginActionAsync} from "../../modules/autorisation/loginActionAsync";

export const LoginPageDispatch = (dispatch: IAppDispatch) => ({
    getLogin: (login: string, password: string) => dispatch(LoginActionAsync.Login(login, password)),
    logOut: () => dispatch(LoginActionAsync.LogOut())
});

export const LoginPageState = createSelector((state: IAppState) => state.loginReduser.token, (token) => ({token}));
