import {reducerWithInitialState} from "typescript-fsa-reducers";
import {ICalendarDate} from "../../core/api/dto/CalendarDate.g";
import {CalendarActions} from "./calendarActions";

interface ICalendar {
    data: ICalendarDate[];
    error: boolean;
    loading: boolean;
}

const CalendarInitialState: ICalendar = {
    data: [] as ICalendarDate[],
    error: false,
    loading: true
};

export const CalendarReducer = reducerWithInitialState(CalendarInitialState)
    .case(CalendarActions.getByMonth.started, (state) => ({
        data: state.data,
        error: false,
        loading: true
    }))
    .case(CalendarActions.getByMonth.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false
    }))
    .case(CalendarActions.getByMonth.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false
    }));
