import React, {SyntheticEvent} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {BlueButton} from "../../ui/BlueButton";
import {ButtonGroup} from "../../ui/ButtonGroup";
import {OutsideClick} from "../../ui/OutsideClick";
import {RedButton} from "../../ui/RedButton";
import {SmallWindow, SmallWindowTitle} from "../../ui/SmallWindow";
import {Input, Lable} from "../loginPage/component/inputWuthLabel";
import {CategoryDispatch, CategoryState} from "./selectors";

interface IProps extends ReturnType<typeof CategoryDispatch>, ReturnType<typeof CategoryState> {
    visibleFormAdd: boolean;
    showFormUpdateCategory: () => void;
    id: string;
}

interface IState {
    nameNewCategory: string;
    errorText: string;
}

class WindowUpdateCategoryConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            nameNewCategory: "",
            errorText: ""
        };
    }

    public render() {
        const filterCategory = this.props.data.filter((item) => item.id === this.props.id)[0];
        const nameCategory = filterCategory ? filterCategory.name : "";
        return (
            <>
                <OutsideClick visible={this.props.visibleFormAdd} onClick={this.showFormUpdateCategory} />
                {this.props.visibleFormAdd ? (
                    <SmallWindow active={this.props.visibleFormAdd}>
                        <SmallWindowTitle>Редактирование категории</SmallWindowTitle>
                        <Lable>Название категории</Lable>
                        <Input onChange={this.inputName} defaultValue={nameCategory} />
                        <ErrorText>{this.state.errorText}</ErrorText>
                        <ButtonGroup center={false}>
                            <BlueButton onClick={this.updateCategory}>Сохранить</BlueButton>
                            <div style={{width: 30}} />
                            <RedButton onClick={this.showFormUpdateCategory}>Отмена</RedButton>
                        </ButtonGroup>
                    </SmallWindow>
                ) : (
                    <></>
                )}
            </>
        );
    }

    private showFormUpdateCategory = () => {
        this.setState({errorText: ""});
        this.props.showFormUpdateCategory();
    };
    private inputName = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({nameNewCategory: event.currentTarget.value});
    };
    private updateCategory = () => {
        if (this.state.nameNewCategory.length !== 0) {
            if (this.props.data.filter((item) => item.name === this.state.nameNewCategory)[0]) {
                this.setState({errorText: "Категория с таким названием уже существует"});
            } else {
                this.setState({errorText: ""});
                this.props.update(this.props.id, this.state.nameNewCategory);
                this.props.initialCategory();
                this.props.showFormUpdateCategory();
            }
        } else {
            this.setState({errorText: "Заполните поле"});
        }
    };
}

export const WindowUpdateCategory = connect(
    CategoryState,
    CategoryDispatch
)(WindowUpdateCategoryConstructor);

const ErrorText = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    color: #ef597d;
`;
