import React from "react";
import styled, {css} from "styled-components";

interface IProps {
    activePage: number;
    countPage: number;
    onClick: (value: number) => void;
}

export class Pagination extends React.Component<IProps> {
    public render() {
        const activePage = this.initialNumerPage();

        return (
            <Container visible={this.props.countPage <= 1}>
                <ButtonPrevNext onClick={this.Prev}>{"< Назад"}</ButtonPrevNext>

                {activePage.map((e) => (
                    // tslint:disable-next-line
                    <Button active={this.props.activePage === e} key={e} onClick={() => this.onClick(e)}>
                        <span>{e}</span>
                    </Button>
                ))}
                {this.props.countPage - this.props.activePage > 5 ? (
                    <BlockEndPage onClick={this.endPage}>... {this.props.countPage}</BlockEndPage>
                ) : (
                    <></>
                )}

                <ButtonPrevNext onClick={this.Next}>{"Вперед >"}</ButtonPrevNext>
            </Container>
        );
    }

    private initialNumerPage = () => {
        const maxPerPage = Math.min(10, this.props.countPage);
        let activePage: number[] = [];

        if (maxPerPage < 10) {
            for (let i = 0; i <= maxPerPage; i++) {
                if (i) {
                    activePage.push(i);
                }
            }
        } else {
            if (this.props.activePage >= this.props.countPage - 5) {
                activePage = [
                    this.props.countPage - 9,
                    this.props.countPage - 8,
                    this.props.countPage - 7,
                    this.props.countPage - 6,
                    this.props.countPage - 5,
                    this.props.countPage - 4,
                    this.props.countPage - 3,
                    this.props.countPage - 2,
                    this.props.countPage - 1,
                    this.props.countPage
                ];
            } else {
                if (this.props.activePage < 5) {
                    activePage = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                } else {
                    activePage = [
                        this.props.activePage - 4,
                        this.props.activePage - 3,
                        this.props.activePage - 2,
                        this.props.activePage - 1,
                        this.props.activePage,
                        this.props.activePage + 1,
                        this.props.activePage + 2,
                        this.props.activePage + 3,
                        this.props.activePage + 4,
                        this.props.activePage + 5
                    ];
                }
            }
        }

        return activePage;
    };

    private Prev = () => {
        if (this.props.activePage - 1 < 1) {
            this.onClick(1);
        } else {
            this.onClick(this.props.activePage - 1);
        }
    };
    private Next = () => {
        if (this.props.activePage + 1 > this.props.countPage) {
            this.onClick(this.props.countPage);
        } else {
            this.onClick(this.props.activePage + 1);
        }
    };
    private endPage = () => {
        this.onClick(this.props.countPage);
    };
    private onClick = (value: number) => {
        window.scrollTo(0, 0);
        this.props.onClick(value);
    };
}

const Container = styled<{visible?: boolean}, "div">("div")`
    display: ${(props) => (props.visible ? "none" : "flex")};
    @media (max-width: 420px) {
        justify-content: center;
    }
`;

const Button = styled<{disabled?: boolean; active?: boolean}, "div">("div")`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    color: #9ea7b0;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin: 2px 7px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.disabled &&
        css`
            cursor: auto;
            pointer-events: none;
        `};
    ${(props) =>
        props.active &&
        css`
            color: #fff;
            background: #1c6ffc;
        `};
    span {
        text-align: center;
        padding-top: 2px;
        user-select: none;
    }
`;

const BlockEndPage = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    color: white;
`;
const ButtonPrevNext = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #384552;
    margin: 0 7px;
    cursor: pointer;
    user-select: none;
`;
