import React from "react";
import {ContainerProps, ValueContainerProps} from "react-select/lib/components/containers";
import {ControlProps} from "react-select/lib/components/Control";
import {IndicatorProps} from "react-select/lib/components/indicators";
import {MenuListComponentProps, MenuProps} from "react-select/lib/components/Menu";
import {OptionProps} from "react-select/lib/components/Option";
import {PlaceholderProps} from "react-select/lib/components/Placeholder";
import {SingleValueProps} from "react-select/lib/components/SingleValue";
import {css, styled} from "src/core/styledComponents";
import {IconCapral} from "./iconCapral";

const createComponent = (displayName = "Component") => {
    // tslint:disable-next-line:no-any
    const Component = (props: any) => {
        const {className, innerRef, innerProps, children} = props;

        return (
            <div className={className} ref={innerRef} {...innerProps}>
                {children}
            </div>
        );
    };

    Component.displayName = displayName;

    return Component;
};

export const reactSelectCreateComponent = createComponent;

// tslint:disable-next-line:no-any
const getStylesMixin = (name: string) => (props: any) => css(props.getStyles(name, props));

export const reactSelectGetStylesMixin = getStylesMixin;

/** Select container */
const SelectContainerElement = createComponent();
// tslint:disable-next-line:no-any
export const SelectContainer = styled<ContainerProps<any>>(SelectContainerElement)`
    ${getStylesMixin("container")};
    flex: 1;
`;

/** Control */
const ControlElement = createComponent();
// tslint:disable-next-line:no-any
export const Control = styled<ControlProps<any> & {hasError: boolean | undefined}>(ControlElement)`
  ${getStylesMixin("control")};

  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.lightGrey};
  transition: box-shadow 0.1s ease-in-out, 
    background-color 0.1s ease-in-out;

  padding: 4px 0;
  height: 20px;
  min-width: 45px;
  
  border: none;
  border-radius: 2px;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  cursor: pointer;

  :hover {}

  ${(props) =>
      props.isFocused &&
      css`
          box-shadow: 0 0 0 2px #0f77ff;
      `}

  ${(props) =>
      props.isDisabled &&
      css`
          box-shadow: inherit;
      `}

  ${(props) =>
      props.hasError &&
      css`
          box-shadow: 0 0 0 2px #ff5d5d;
      `}
`;

/** Value container */
const ValueContainerElement = createComponent();
// tslint:disable-next-line:no-any
export const ValueContainer = styled<ValueContainerProps<any>>(ValueContainerElement)`
    ${getStylesMixin("valueContainer")};
    padding: 0;
    input {
        caret-color: ${(props) => props.theme.colors.lightGrey};
        color: red !important;
        font-family: Roboto, sans-serif;
    }
`;

/** Placeholder */
const PlaceholderElement = createComponent();
// tslint:disable-next-line:no-any
export const Placeholder = styled<PlaceholderProps<any>>(PlaceholderElement)`
    ${getStylesMixin("placeholder")};
    color: #b2b2b2 !important;
`;

/** Option */
const OptionElement = createComponent();

// tslint:disable-next-line:no-any
export const Option = styled<OptionProps<any>>(OptionElement)`
  ${getStylesMixin("option")};

  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.slate};

  transition: background-color 0.1s;
  height: 20px;
  padding: 1px 10px;
  font-size: 12px;

  :hover, :active {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.rose} !important;
  }

  ${(props) =>
      props.isSelected &&
      css`
          background-color: ${props.theme.colors.blueAccent};
          color: ${props.theme.colors.white};
          cursor: default;
      `}

  ${(props) =>
      props.isFocused &&
      css`
          background-color: ${props.theme.colors.lightGrey};
          color: ${props.theme.colors.slate};
      `}

  ${(props) =>
      props.isDisabled &&
      css`
          color: ${props.theme.colors.mainGrey};
          cursor: default;
      `}
`;

// Menu
export const MenuElement = createComponent();
// tslint:disable-next-line:no-any
export const Menu = styled<MenuProps<any>>(MenuElement)`
    ${getStylesMixin("menu")};
    margin-top: 4px;
    margin-bottom: 4px;
    color: #000000;

    overflow: hidden;
    border-radius: 2px;
    z-index: 999;
`;

export const MenuListElement = createComponent();
// tslint:disable-next-line:no-any
export const MenuList = styled<MenuListComponentProps<any>>(MenuListElement)`
    ${getStylesMixin("menuList")};
    flex-direction: column;
    max-height: 400px;
`;

// Value
export const SingleValueElement = createComponent();
// tslint:disable-next-line:no-any
export const SingleValue = styled<SingleValueProps<any>>(SingleValueElement)`
    ${getStylesMixin("singleValue")};
    margin-left: 2px;
    color: #000000;
    width: 100%;
    flex: 1;
`;

// tslint:disable-next-line:no-any
export const DropdownIndicatorElement = ({className, innerRef, innerProps}: IndicatorProps<any> & {innerRef: any}) => (
    <div ref={innerRef} className={className} {...innerProps}>
        <IconCapral />
    </div>
);

// tslint:disable-next-line:no-any
export const DropdownIndicator = styled<IndicatorProps<any>>(DropdownIndicatorElement)`
    ${getStylesMixin("dropdownIndicator")};
    ${(props) => (props.selectProps.menuIsOpen ? "transform: rotate(180deg);" : null)};
`;

export const IndicatorSeparator = () => null;
