import {styled} from "../../core/styledComponents";
import {NavLink} from "./NavLink";

export const StyledNavLink = styled(NavLink)`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #384552;
    padding: 15px 15px 15px 60px;
    width: 100%;

    :hover,
    :active,
    :focus {
        text-decoration: none;
        background: #1c6ffc;
        color: white;
    }
`;
