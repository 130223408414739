import React from "react";
import styled from "styled-components";
// import { CalendarCell } from './CalendarCell';
import {CalendarRow} from "./CalendarRow";

export class DaysOfWeek extends React.Component {
    public render(): JSX.Element {
        const daysOfWeek = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"].map(
            (day) => <Container key={day}>{day}</Container>
        );
        return <CalendarRow>{daysOfWeek}</CalendarRow>;
    }
}

const Container = styled.div`
    display: flex;
    font-family: Roboto;
    flex: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    justify-content: center;
    color: #384552;
    margin-bottom: 10px;
`;
