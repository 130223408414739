import {ICity} from "src/core/api/dto/City.g";
import {reducerWithInitialState} from "typescript-fsa-reducers";
import {CityAction} from "./cityAction";

interface ICityState {
    data: ICity[];
    loading: boolean;
    error: boolean;
}

const CityState: ICityState = {
    data: [],
    loading: false,
    error: false
};

export const CityReducer = reducerWithInitialState(CityState)
    .case(CityAction.getAll.started, (state) => ({data: state.data, loading: true, error: false}))
    .case(CityAction.getAll.done, (state, payload) => ({data: payload.result, loading: false, error: false}))
    .case(CityAction.getAll.failed, (state) => ({data: state.data, loading: false, error: true}));
