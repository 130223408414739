import {IOption} from "src/components/select";

export enum DayType {
    Undefined = "Undefined",
    OffDay = "OffDay",
    Holiday = "Holiday",
    ShortDay = "ShortDay",
    ProfessionalHoliday = "ProfessionalHoliday"
}

export const optionCategoryCalendar: IOption[] = [
    {value: "Holiday", label: "Праздник"},
    {value: "ShortDay", label: "Сокращённый день"},
    {value: "ProfessionalHoliday", label: "Профессиональный праздник"},
    {value: "OffDay", label: "Выходной (без описания)"}
];
