import React from "react";
import {styled} from "../../core/styledComponents";

interface IProps {
    state: "up" | "down" | "notActive";
}

export const IconTriangleFilter = ({state}: IProps) => (
    <Block>
        <svg
            width="5"
            height="5"
            viewBox="0 0 4 4"
            fill="none"
            display={state === "down" ? "none" : "block"}
            className={"topTriangle"}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.250032 4L3.74998 4C3.81773 4 3.8763 3.95602 3.92579 3.86814C3.97528 3.78016 4 3.67603 4 3.55566C4 3.43528 3.97528 3.33108 3.92579 3.24315L2.17581 0.132029C2.12627 0.04405 2.0677 4.76837e-07 2 4.76837e-07C1.9323 4.76837e-07 1.87368 0.04405 1.82423 0.132029L0.0742505 3.24317C0.0248184 3.33108 0 3.43528 0 3.55568C0 3.67603 0.0248048 3.78016 0.0742505 3.86817C0.123696 3.95612 0.182322 4 0.250032 4Z"
                fill="#9EA7B0"
            />
        </svg>
        <svg
            width="5"
            height="5"
            viewBox="0 0 4 4"
            fill="none"
            display={state === "up" ? "none" : "block"}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.74997 -9.79835e-08H0.250019C0.182267 -9.79835e-08 0.123696 0.0439772 0.0742094 0.131859C0.0247228 0.219837 1.08558e-08 0.323967 1.08558e-08 0.444344C1.08558e-08 0.564722 0.0247228 0.668925 0.0742094 0.756855L1.82419 3.86797C1.87373 3.95595 1.9323 4 2 4C2.0677 4 2.12632 3.95595 2.17577 3.86797L3.92575 0.75683C3.97518 0.668925 4 0.564722 4 0.44432C4 0.323967 3.9752 0.219837 3.92575 0.131834C3.8763 0.0438797 3.81768 -9.79835e-08 3.74997 -9.79835e-08Z"
                fill="#9EA7B0"
            />
        </svg>
    </Block>
);

const Block = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    justify-content: center;

    .topTriangle {
        margin-bottom: 4px;
    }
`;
