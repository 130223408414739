/*tslint:disable*/
type Action<T> = () => T;

export class BaseRequest {
    static noAuthHandler?: (error: Error) => void;
    static handleError = (error: any): Promise<any> => {
        if (BaseRequest.noAuthHandler) {
            BaseRequest.noAuthHandler(error);
        }
        return Promise.reject(error.message || error);
    };

    protected baseurl: string;

    static token: string;
    static getToken: Action<string> = () => BaseRequest.token;
    static setToken: (token: string) => void = (token) => (BaseRequest.token = token);

    async fetch(url: string, config: Object): Promise<any> {
        let headers = {
            cookie: "",
            Accept: "application/json",
            "Content-Type": "application/json"
        };

        if (BaseRequest.getToken()) {
            headers = {...headers, cookie: `access_token=${BaseRequest.token}`};
        }
        const response = await fetch(url, Object.assign({headers: headers}, config));
        if (response.status == 401) {
            throw response;
        } else if (!response.status || response.status < 200 || response.status >= 300) {
            const error = await response.json();
            throw error;
        }
        return response;
    }

    protected q(params: {[key: string]: string | number | boolean | string | Date | null}): string {
        /*****************Функция проверяет что параметр существует**************/
        function assertNotNull(param: string | number | boolean | Date | null) {
            return param === null ? "" : param;
        }

        const query = Object.keys(params)
            .filter((k) => params[k] != null)
            .map((k) => `${k}=${encodeURIComponent(assertNotNull(params[k]).toString())}`)
            .join("&");

        return query ? `?${query}` : "";
    }

    getObjectFlatter(): (any: any, string?: any) => string {
        const flatObject = {};

        return function objectToFlat(node: any, parentName?: string): string {
            let queryArgs: string[] = [];
            parentName = parentName ? parentName : "";

            if (node instanceof Object) {
                for (const subNodeName in node) {
                    if (node[subNodeName] instanceof Object) {
                        objectToFlat(node[subNodeName], subNodeName);
                    } else {
                        const newNodeValue = node[subNodeName];
                        const newNodeName = parentName === "" ? subNodeName : parentName + "." + subNodeName;

                        if (newNodeValue) {
                            flatObject[newNodeName] = newNodeValue;
                        }
                    }
                }
            }

            for (const key in flatObject) {
                queryArgs.push(key + "=" + flatObject[key]);
            }

            return queryArgs.join("&");
        };
    }
}
