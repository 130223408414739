import React from "react";
import {ValueContainerProps} from "react-select/lib/components/containers";
import {ControlProps} from "react-select/lib/components/Control";
import {MenuProps} from "react-select/lib/components/Menu";
import {OptionProps} from "react-select/lib/components/Option";
import {SingleValueProps} from "react-select/lib/components/SingleValue";
// tslint:disable-next-line
import {IOption, Select as BaseSelect} from "src/components/select";
import {
    MenuListElement,
    reactSelectCreateComponent,
    reactSelectGetStylesMixin
} from "src/components/select/defaultComponents";
import {css, styled} from "src/core/styledComponents";

const ControlComponent = reactSelectCreateComponent();
// tslint:disable-next-line:no-any
export const Control = styled<ControlProps<any> & {hasError: boolean | undefined}>(ControlComponent)`
  background-color: white;
  color: #000000;
  transition: box-shadow 0.1s ease-in-out, 
    background-color 0.1s ease-in-out;

  padding: 8px 8px;
  width: 100%;
 
  font-family: Roboto, sans-serif;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #9EA7B0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;


  ${(props) =>
      props.isFocused &&
      css`
          //   box-shadow: 0 0 0 2px #0f77ff;
      `}

  ${(props) =>
      props.isDisabled &&
      css`
          box-shadow: inherit;
      `}

  ${(props) =>
      props.hasError &&
      css`
          //   box-shadow: 0 0 0 2px #ff5d5d;
      `}
`;

const OptionElement = reactSelectCreateComponent();

// tslint:disable-next-line:no-any
export const Option = styled<OptionProps<any>>(OptionElement)`

  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.white};
  color: #000000;
  border-radius: 4px;

  padding: 1px 5px;
  font-size: 14px;
  transition: all 600ms ease;

  :hover, :active {
    cursor: pointer;
    background-color: #0f77ff !important;
    color: white;
    width: 100%;
  }

  ${(props) =>
      props.isSelected &&
      css`
          background-color: #0f77ff;
          color: white;
          cursor: default;
      `}

  ${(props) =>
      props.isFocused &&
      css`
          background-color: ${props.theme.colors.lightGrey};
          color: ${props.theme.colors.slate};
      `}

  ${(props) =>
      props.isDisabled &&
      css`
          color: ${props.theme.colors.mainGrey};
          cursor: default;
      `}
`;

const ValueContainerElement = reactSelectCreateComponent();
// tslint:disable-next-line:no-any
export const ValueContainer = styled<ValueContainerProps<any>>(ValueContainerElement)`
    input {
        caret-color: ${(props) => props.theme.colors.lightGrey};
        color: red !important;
        font-family: Roboto, sans-serif;
        position: absolute;
    }
    min-height: 20px;
    flex: 1;
`;

export const SingleValueElement = reactSelectCreateComponent();
// tslint:disable-next-line:no-any
export const SingleValue = styled<SingleValueProps<any>>(SingleValueElement)`
    margin-left: 2px;
    color: ${(props) => props.theme.colors.black};
    font-size: 14px;
    font-family: Roboto, sans-serif;
    background-color: ${(props) => props.theme.colors.white};
    width: 100%;
    flex: 1;
`;

// Menu
const MenuElement = reactSelectCreateComponent();
// tslint:disable-next-line:no-any
export const Menu = styled<MenuProps<any>>(MenuElement)`
    ${reactSelectGetStylesMixin("menu")};
    margin-bottom: 4px;
    color: #000000;
    padding: 10px;

    top: 33px;
    width: auto;
    min-width: 100%;

    border: solid 0 white;
    //overflow: hidden;
    border-radius: 0 0 2px 2px;
    z-index: 1;
`;
// tslint:disable-next-line:no-any
export const MenuList = styled<MenuProps<any>>(MenuListElement)`
    ${reactSelectGetStylesMixin("menuList")};
    flex-direction: column;
    overflow: auto;
    max-height: 400px;
`;

// tslint:disable-next-line:no-any
export const Select = (props: any) => {
    const {options, value} = props;
    let label = "";
    const option = options.filter((item: IOption) => item.value === value);
    if (option.length) {
        label = option[0].label;
    }
    return (
        <BaseSelect
            {...props}
            components={{Control, ValueContainer, SingleValue, Option, Menu, MenuList}}
            value={{
                value: props.value,
                label
            }}
        />
    );
};
