import {INewsShort} from "./dto/NewsShort.g";
import {NewsApiRequest} from "./NewsApiRequest.g";

export class NewsWitUploadApiRequest extends NewsApiRequest {
    public addNews(file: File, image: File, news: INewsShort, config?: object): Promise<Response> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("image", image);
        formData.append("news", JSON.stringify(news));

        const headers = new Headers({
            Accept: "application/json"
        });

        return fetch(
            `/allApi/admin/News/`,
            Object.assign(
                {
                    headers,
                    method: "POST",
                    body: formData
                },
                config
            )
        ).then((response) => {
            if (!response.status || response.status < 200 || response.status >= 300) {
                throw response;
            }
            return response;
        });
    }

    public updateNews(id: string, file: File, image: File, news: INewsShort, config?: object): Promise<Response> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("image", image);
        formData.append("news", JSON.stringify(news));

        const headers = new Headers({
            Accept: "application/json"
        });

        return fetch(
            `/allApi/admin/News/${id}`,
            Object.assign(
                {
                    headers,
                    method: "PUT",
                    body: formData
                },
                config
            )
        ).then((response) => {
            if (!response.status || response.status < 200 || response.status >= 300) {
                throw response;
            }
            return response;
        });
    }
}
