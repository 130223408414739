import React, {Component} from "react";
import styled from "styled-components";
import {AuthorisationForm} from "../../components/loginPage/authorisationForm";

export class LoginPage extends Component {
    public render(): React.ReactNode {
        return (
            <Container>
                <Title>
                    Админ панель
                    <br /> Бизнес-портала Приднестровья
                </Title>
                <AuthorisationBlock>
                    <AuthorisationForm />
                </AuthorisationBlock>
            </Container>
        );
    }
}

const Title = styled("div")`
    font-size: 24px;
    text-align: center;
    color: #384552;
    font-family: Roboto, sans-serif;
    margin-top: 6%;
    margin-bottom: 48px;
`;

const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const AuthorisationBlock = styled.div`
    display: flex;
`;
