import React from "react";

export const IconCross = () => (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 1.60531L12.59 0.195312L7 5.78531L1.41 0.195312L0 1.60531L5.59 7.19531L0 12.7853L1.41 14.1953L7 8.60531L12.59 14.1953L14 12.7853L8.41 7.19531L14 1.60531Z"
            fill="#9EA7B0"
        />
    </svg>
);
