import {reducerWithInitialState} from "typescript-fsa-reducers";
import {getCookie} from "../../common/getCookie";
import {ITokenResponse} from "../../core/api/dto/TokenResponse.g";
import {LoginAction} from "./loginAction";

interface ILoading {
    token: ITokenResponse;
    loading: boolean;
    error: boolean;
    authorisation: boolean;
}

const LoginState: ILoading = {
    token: {
        token: getCookie("access_token")
    } as ITokenResponse,
    loading: false,
    error: false,
    authorisation: false
};

export const LoginReducer = reducerWithInitialState(LoginState)
    .case(LoginAction.login.started, (state) => ({
        token: state.token,
        loading: true,
        error: false,
        authorisation: false
    }))
    .case(LoginAction.login.done, (state, payload) => ({
        token: payload.result,
        loading: false,
        error: false,
        authorisation: true
    }))
    .case(LoginAction.login.failed, (state) => ({
        token: state.token,
        loading: false,
        error: true,
        authorisation: false
    }))

    .case(LoginAction.logOut.started, () => ({
        token: {} as ITokenResponse,
        loading: false,
        error: false,
        authorisation: false
    }))
    .case(LoginAction.logOut.done, () => ({
        token: {} as ITokenResponse,
        loading: false,
        error: false,
        authorisation: false
    }))
    .case(LoginAction.logOut.failed, () => ({
        token: {} as ITokenResponse,
        loading: false,
        error: false,
        authorisation: false
    }));
