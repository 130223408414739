import * as moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Loader} from "src/shared/icons/Loader";
import styled from "styled-components";
import {Container} from "../ui/Container";
import {Days} from "./calendar/Days";
import {DaysOfWeek} from "./calendar/DaysOfWeek";
import {FormAddCelebrate} from "./calendar/formAddCelebrate";
import {HolidayTransfer} from "./calendar/holidayTransfer";
import {PaginationCalendar} from "./calendar/paginationCalendar";
import {CalendarDispatch, CalendarState} from "./calendar/selectors";

interface IProps extends ReturnType<typeof CalendarDispatch>, ReturnType<typeof CalendarState> {}

interface IState {
    month: moment.Moment;
    currentDay: moment.Moment;
    visibleFormAddCelebrate: boolean;
    visibleFormHolidayTransfer: boolean;
}

class CalendarPageConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            month: moment().startOf("month"),
            currentDay: moment(),
            visibleFormAddCelebrate: false,
            visibleFormHolidayTransfer: false
        };
    }

    public componentDidMount() {
        this.props.initialCalendar(this.state.month.month() + 1, this.state.month.year());
    }

    public render() {
        const weeks = Array<number>(6)
            .fill(1)
            .map((item, week) => <Days key={week} week={week} onClick={this.onClick} startDay={this.state.month} />);

        return (
            <Container>
                <Heder>
                    <PaginationCalendar
                        month={this.state.month}
                        prevMonth={this.prevMonth}
                        nextMonth={this.nextMonth}
                    />
                </Heder>
                <CalendarContainer>
                    {this.props.loading ? (
                        <div style={{height: 475}}>
                            <Loader />
                        </div>
                    ) : (
                        <ContentContainer>
                            <DaysOfWeek />
                            {weeks}
                        </ContentContainer>
                    )}
                    {this.state.visibleFormAddCelebrate ? (
                        <FormAddCelebrate
                            date={this.state.currentDay}
                            outsideClick={this.outsideClick}
                            visible={this.state.visibleFormAddCelebrate}
                            data={
                                this.props.calendarData.filter(
                                    (item) => moment(item.date).date() === this.state.currentDay.date()
                                )[0]
                            }
                        />
                    ) : (
                        <></>
                    )}
                    <HolidayTransfer
                        year={this.state.month.year()}
                        visibleForm={this.state.visibleFormHolidayTransfer}
                        showForm={this.outsideClick}
                    />
                </CalendarContainer>

                <ContainerWeekend>
                    <ButtonWeekends onClick={this.visibleHolidayTransfer}>Скопировать праздники</ButtonWeekends>
                    <ButtonWeekends onClick={this.fillYearWeekend}>
                        Отметить все субботы и воскресенья в году как выходные
                    </ButtonWeekends>
                </ContainerWeekend>
            </Container>
        );
    }

    private prevMonth = async () => {
        await this.setState({month: this.state.month.clone().add(-1, "month")});
        await this.props.initialCalendar(this.state.month.month() + 1, this.state.month.year());
    };

    private nextMonth = async () => {
        await this.setState({month: this.state.month.clone().add(1, "month")});
        await this.props.initialCalendar(this.state.month.month() + 1, this.state.month.year());
    };

    private onClick = (day: moment.Moment) => {
        this.setState({currentDay: day, visibleFormAddCelebrate: true});
    };
    private outsideClick = () => {
        this.setState({visibleFormAddCelebrate: false, visibleFormHolidayTransfer: false});
    };

    private fillYearWeekend = async () => {
        await this.props.fillYearWeekend(Number(moment(this.state.month).format("YYYY")));
        await this.props.initialCalendar(this.state.month.month() + 1, this.state.month.year());
    };

    private visibleHolidayTransfer = () => {
        this.setState({visibleFormHolidayTransfer: true});
    };
}

export const CalendarPage = connect(
    CalendarState,
    CalendarDispatch
)(CalendarPageConstructor);

const CalendarContainer = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
`;
const Heder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
`;
const ContentContainer = styled("div")`
    padding: 0 0 10px 0;
`;
const ContainerWeekend = styled.div`
    display: flex;
    width: 100%;
    background: #fff;
    margin-top: 10px;
    padding: 8px 15px;
    justify-content: space-between;
`;
const ButtonWeekends = styled.div`
    background: #1c6ffc;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    padding: 8px 40px;
`;
