import * as moment from "moment";
import {CalendarType} from "../../core/api/dto/CalendarType.g";
import {requestsRepository} from "../../core/api/RequestRepository";
import {IThunkAction} from "../../core/store/mainReducer";
import {TooltipsAction} from "../tooltips/tooltipsAction";
import {CalendarFilesActions, ICalendarFilesInitialParams} from "./calendarFilesAction";

export class CalendarFilesActionAsync {
    public static InitialFiles(): IThunkAction {
        return async (dispatch) => {
            try {
                await dispatch(CalendarFilesActions.initialFiles.started({params: {}}));

                const result: ICalendarFilesInitialParams[] = [
                    {
                        year: Number(moment().format("YYYY")),
                        file: {
                            fiveDay: await request(CalendarType.FiveDays, Number(moment().format("YYYY"))),
                            sixDay: await request(CalendarType.SixDays, Number(moment().format("YYYY")))
                        }
                    },
                    {
                        year: Number(moment().format("YYYY")) + 1,
                        file: {
                            fiveDay: await request(CalendarType.FiveDays, Number(moment().format("YYYY")) + 1),
                            sixDay: await request(CalendarType.SixDays, Number(moment().format("YYYY")) + 1)
                        }
                    },
                    {
                        year: Number(moment().format("YYYY")) + 2,
                        file: {
                            fiveDay: await request(CalendarType.FiveDays, Number(moment().format("YYYY")) + 2),
                            sixDay: await request(CalendarType.SixDays, Number(moment().format("YYYY")) + 2)
                        }
                    },
                    {
                        year: Number(moment().format("YYYY")) + 3,
                        file: {
                            fiveDay: await request(CalendarType.FiveDays, Number(moment().format("YYYY")) + 3),
                            sixDay: await request(CalendarType.SixDays, Number(moment().format("YYYY")) + 3)
                        }
                    },
                    {
                        year: Number(moment().format("YYYY")) + 4,
                        file: {
                            fiveDay: await request(CalendarType.FiveDays, Number(moment().format("YYYY")) + 4),
                            sixDay: await request(CalendarType.SixDays, Number(moment().format("YYYY")) + 4)
                        }
                    }
                ];

                await dispatch(CalendarFilesActions.initialFiles.done({params: {}, result}));
            } catch (error) {
                await dispatch(CalendarFilesActions.initialFiles.failed({params: {}, error}));
            }
        };
    }

    public static LoadFile(file: File, year: number, type: CalendarType): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.addFile(file, year, type);
                dispatch(TooltipsAction.showTooltips("Файл успешно добавлен"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удалось добавить файл. Повторите попытку позже."
                    )
                );
            }
        };
    }

    public static DeleteFile(id: number): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.deleteFile(id);
                dispatch(TooltipsAction.showTooltips("Файл успешно удалён"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удалось удалить файл. Повторите попытку позже."
                    )
                );
            }
        };
    }
}

const request = async (type: CalendarType, year: number) => {
    let result;
    try {
        result = await requestsRepository.calendarApiRequest.getFiles(type, year);
        return result;
    } catch (e) {
        return undefined;
    }
};
