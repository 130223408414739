import {URLbackend} from "../../common/constants";
import {BaseRequest} from "./BaseRequest";
import {ICalendarDate} from "./dto/CalendarDate.g";
import {ICalendarDateShort} from "./dto/CalendarDateShort.g";
import {ICalendarFiles} from "./dto/CalendarFiles.g";
import {CalendarType} from "./dto/CalendarType.g";

/*tslint:disable*/

export class CalendarApiRequest extends BaseRequest {
    constructor() {
        super();
        this.baseurl = URLbackend;
    }

    create(date: ICalendarDateShort, config?: Object): Promise<string> {
        return this.fetch(
            `/allApi/admin/Calendar`,
            Object.assign(
                {
                    method: "POST",
                    body: JSON.stringify(date)
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    update(id: string, date: ICalendarDateShort, config?: Object): Promise<void> {
        return this.fetch(
            `/allApi/admin/Calendar/${id}`,
            Object.assign(
                {
                    method: "PUT",
                    body: JSON.stringify(date)
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    delete(id: string, config?: Object): Promise<void> {
        return this.fetch(
            `/allApi/admin/Calendar/${id}`,
            Object.assign(
                {
                    method: "DELETE"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    getAll(config?: Object): Promise<ICalendarDate[]> {
        return this.fetch(
            `/allApi/admin/Calendar`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    getByMonth(month: number, year: number, config?: Object): Promise<ICalendarDate[]> {
        const p: {[key: string]: any} = {};
        p["month"] = month;
        p["year"] = year;
        return this.fetch(
            `/allApi/admin/Calendar/GetByMonth?${this.getObjectFlatter()(p)}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    fillYearWeekend(year: number, config?: Object): Promise<void> {
        const p: {[key: string]: any} = {};
        p["year"] = year;
        return this.fetch(
            `/allApi/admin/Calendar/FillYearWeekends?${this.getObjectFlatter()(p)}`,
            Object.assign(
                {
                    method: "POST"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    getFiles(type: CalendarType, year: number, config?: Object): Promise<ICalendarFiles> {
        const p: {[key: string]: any} = {};
        p["type"] = type;
        p["year"] = year;
        return this.fetch(
            `/allApi/admin/Calendar/GetFiles?${this.getObjectFlatter()(p)}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    addFile(file: File, year: number, type: CalendarType, config?: Object): Promise<void> {
        const formData = new FormData();
        formData.append("file", file);
        let headers = new Headers({
            Accept: "application/json"
        });

        return this.fetch(
            `/allApi/admin/Calendar/UpdateFile?type=${type}&year=${year}`,
            Object.assign(
                {
                    headers: headers,
                    method: "POST",
                    body: formData
                },
                config
            )
        )
            .then((response) => response)
            .catch(BaseRequest.handleError);
    }

    deleteFile(id: number, config?: Object): Promise<void> {
        return this.fetch(
            `/allApi/admin/Calendar/DeleteFile/${id}`,
            Object.assign(
                {
                    method: "DELETE"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    fillYear(fromYear: number, toYear: number, config?: Object): Promise<void> {
        const p: {[key: string]: any} = {};
        p["fromYear"] = fromYear;
        p["toYear"] = toYear;
        return this.fetch(
            `/allApi/admin/Calendar/FillYearHolidays/?${this.getObjectFlatter()(p)}`,
            Object.assign(
                {
                    method: "POST"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }
}
