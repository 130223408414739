import React from "react";

export const IconCalendar = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.5556 19.8H2.44444V7.7H19.5556V19.8ZM15.8889 0V2.2H6.11111V0H3.66667V2.2H2.44444C1.08778 2.2 0 3.179 0 4.4V19.8C0 20.3835 0.257539 20.9431 0.715961 21.3556C1.17438 21.7682 1.79614 22 2.44444 22H19.5556C20.2039 22 20.8256 21.7682 21.284 21.3556C21.7425 20.9431 22 20.3835 22 19.8V4.4C22 3.179 20.9 2.2 19.5556 2.2H18.3333V0H15.8889ZM17.1111 12.1H11V17.6H17.1111V12.1Z"
            fill="#384552"
        />
    </svg>
);
