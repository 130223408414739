import {INews} from "src/core/api/dto/News.g";
import {IPageResult} from "../../core/api/dto/PageResult.g";
import {actionCreator} from "../../core/store/common/actionCreator";
import {ISortParamDto} from "src/core/api/dto/SortParamDto.g";

export class NewsAction {
    public static getByFilter = actionCreator.async<IEmpty, IPageResult<INews>, Error>("News/BY_PAGE");
    public static deleteUpdateAdd = actionCreator.async<IEmpty, IEmpty, Error>("News/DELETE");
    public static updateSortCategoryParam = actionCreator<string>("News/SORTC_CATEGORY");
    public static updateSortTitleParam = actionCreator<string>("News/SORT_TITLE");
    public static updateSortFilterParam = actionCreator<ISortParamDto>("News/SORT_FILTER");
}
