import styled from "styled-components";

export const TH = styled.th`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
    color: #9ea7b0;
    max-width: 330px;
    background: #f7f7f7;
    white-space: nowrap;
    padding: 10px 24px;
    min-width: 140px;
    text-align: left;
    cursor: pointer;
    user-select: none;
`;
