import React, {SyntheticEvent} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {BlueButton} from "../../ui/BlueButton";
import {ButtonGroup} from "../../ui/ButtonGroup";
import {OutsideClick} from "../../ui/OutsideClick";
import {RedButton} from "../../ui/RedButton";
import {SmallWindow, SmallWindowTitle} from "../../ui/SmallWindow";
import {Input, Lable} from "../loginPage/component/inputWuthLabel";
import {CategoryDispatch, CategoryState} from "./selectors";

interface IProps extends ReturnType<typeof CategoryDispatch>, ReturnType<typeof CategoryState> {
    visibleFormAdd: boolean;
    showFormAddCategory: () => void;
}

interface IState {
    nameNewCategory: string;
    errorText: string;
}

class WindowAddCategoryConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            nameNewCategory: "",
            errorText: ""
        };
    }

    public render() {
        return (
            <>
                <OutsideClick visible={this.props.visibleFormAdd} onClick={this.showFormAddCategory} />
                <SmallWindow active={this.props.visibleFormAdd}>
                    <SmallWindowTitle>Создание категории</SmallWindowTitle>
                    <Lable>Название категории</Lable>
                    <Input onChange={this.inputName} value={this.state.nameNewCategory} />
                    <ErrorText>{this.state.errorText}</ErrorText>
                    <ButtonGroup center={false}>
                        <BlueButton onClick={this.addCategory}>Создать</BlueButton>
                        <div style={{width: 30}} />
                        <RedButton onClick={this.showFormAddCategory}>Отмена</RedButton>
                    </ButtonGroup>
                </SmallWindow>
            </>
        );
    }

    private showFormAddCategory = () => {
        this.setState({errorText: "", nameNewCategory: ""});
        this.props.showFormAddCategory();
    };
    private inputName = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({nameNewCategory: event.currentTarget.value});
    };
    private addCategory = () => {
        if (this.state.nameNewCategory.length !== 0) {
            if (this.props.data.filter((item) => item.name === this.state.nameNewCategory)[0]) {
                this.setState({errorText: "Категория с таким названием уже существует"});
            } else {
                this.setState({errorText: "", nameNewCategory: ""});
                this.props.create(this.state.nameNewCategory);
                this.props.initialCategory();
                this.props.showFormAddCategory();
            }
        } else {
            this.setState({errorText: "Заполните поле"});
        }
    };
}

export const WindowAddCategory = connect(
    CategoryState,
    CategoryDispatch
)(WindowAddCategoryConstructor);

const ErrorText = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    color: #ef597d;
`;
