import React from "react";
import {connect} from "react-redux";
import {styled} from "../../core/styledComponents";
import {IconCross} from "../../shared/icons/IconCross";
import {BlueButton} from "../../ui/BlueButton";
import {ButtonGroup} from "../../ui/ButtonGroup";
import {OutsideClick} from "../../ui/OutsideClick";
import {RedButton} from "../../ui/RedButton";
import {Select} from "../../ui/Select";
import {SmallWindow, SmallWindowTitle} from "../../ui/SmallWindow";
import {IOption} from "../select";
import {CrossContainer} from "./formAddCelebrate";
import {CalendarDispatch} from "./selectors";

interface IProps extends ReturnType<typeof CalendarDispatch> {
    visibleForm: boolean;
    showForm: () => void;
    year: number;
}

interface IState {
    toYear: number;
}

class HolidayTransferConstructor extends React.Component<IProps, IState> {
    private years: IOption[] = [];

    constructor(props: IProps) {
        super(props);
        this.state = {
            toYear: this.props.year + 1
        };
        for (let i = 0; i < 50; i++) {
            this.years.push({value: props.year + i, label: (props.year + i).toString()});
        }
    }

    public render() {
        return (
            <>
                <OutsideClick visible={this.props.visibleForm} onClick={this.props.showForm} />
                <SmallWindow active={this.props.visibleForm}>
                    <CrossContainer onClick={this.props.showForm}>
                        <IconCross />
                    </CrossContainer>
                    <SmallWindowTitle>Перенос праздника из {this.props.year}</SmallWindowTitle>
                    <Lable>Выберите год</Lable>
                    <Select options={this.years} value={this.state.toYear} onChange={this.changeYear} />
                    <Lable>Укажите в какой год скопировать праздники за {this.props.year}?</Lable>
                    <ButtonGroup center={false}>
                        <BlueButton onClick={this.transfer}>Создать</BlueButton>
                        <div style={{width: 30}} />
                        <RedButton onClick={this.props.showForm}>Отмена</RedButton>
                    </ButtonGroup>
                </SmallWindow>
            </>
        );
    }

    private transfer = () => {
        this.props.transfer(this.props.year, this.state.toYear);
        this.props.showForm();
    };

    private changeYear = (value: string) => {
        this.setState({toYear: Number(value)});
    };
}

export const HolidayTransfer = connect(
    undefined,
    CalendarDispatch
)(HolidayTransferConstructor);

const Lable = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #384552;
    margin-top: 10px;
`;
