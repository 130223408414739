import styled from "styled-components";

export const OutsideClick = styled<{visible: boolean}, "div">("div")`
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    display: ${(props) => (props.visible ? "flex" : "none")};
    z-index: 998;
`;
