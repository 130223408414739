import React from "react";

export class IconView extends React.Component {
    public render() {
        return (
            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 4.8C11.132 4.8 10.2996 5.13714 9.68583 5.73726C9.07208 6.33737 8.72727 7.15131 8.72727 8C8.72727 8.84869 9.07208 9.66263 9.68583 10.2627C10.2996 10.8629 11.132 11.2 12 11.2C12.868 11.2 13.7004 10.8629 14.3142 10.2627C14.9279 9.66263 15.2727 8.84869 15.2727 8C15.2727 7.15131 14.9279 6.33737 14.3142 5.73726C13.7004 5.13714 12.868 4.8 12 4.8ZM12 13.3333C10.5534 13.3333 9.16598 12.7714 8.14305 11.7712C7.12013 10.771 6.54545 9.41449 6.54545 8C6.54545 6.58551 7.12013 5.22896 8.14305 4.22876C9.16598 3.22857 10.5534 2.66667 12 2.66667C13.4466 2.66667 14.834 3.22857 15.8569 4.22876C16.8799 5.22896 17.4545 6.58551 17.4545 8C17.4545 9.41449 16.8799 10.771 15.8569 11.7712C14.834 12.7714 13.4466 13.3333 12 13.3333ZM12 0C6.54545 0 1.88727 3.31733 0 8C1.88727 12.6827 6.54545 16 12 16C17.4545 16 22.1127 12.6827 24 8C22.1127 3.31733 17.4545 0 12 0Z"
                    fill="#1C6FFC"
                />
            </svg>
        );
    }
}
