import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import styled from "styled-components";
import {URLbackend} from "../common/constants";
import {INews} from "../core/api/dto/News.g";
import {BlueButton} from "../ui/BlueButton";
import {NewsDispatch, NewsState} from "./newsPage/selectors";

interface IProps
    extends ReturnType<typeof NewsDispatch>,
        ReturnType<typeof NewsState>,
        RouteComponentProps<{id: string}> {}

interface IState {
    data: INews;
}

class ViewNewsConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: this.props.data.items
                ? this.props.data.items.filter((item) => item.id === this.props.match.params.id)[0]
                : ({} as INews)
        };
    }

    public componentDidMount() {
        this.props.initialNewsById(this.props.match.params.id);
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                data: this.props.data.items
                    ? this.props.data.items.filter((item) => item.id === this.props.match.params.id)[0]
                    : ({} as INews)
            });
        }
    }

    public render() {
        const {data} = this.state;
        return (
            <Container>
                <TopContainer>
                    <BlueButton onClick={this.goBack}>Вернуться</BlueButton>
                    <BlueButton onClick={this.UpdateNews}>Редактировать</BlueButton>
                </TopContainer>
                {data ? (
                    <div className={"container"}>
                        <NewsBlock className={"col-md-8"}>
                            <Title>{data.title}</Title>
                            {data.imagePath && data.imagePath.length !== 0 ? (
                                <Image src={`${URLbackend}/${data.imagePath}`} />
                            ) : (
                                <></>
                            )}
                            <FullContent dangerouslySetInnerHTML={{__html: data.description}} />
                        </NewsBlock>
                    </div>
                ) : (
                    <>Ошибка загрузки новости</>
                )}
            </Container>
        );
    }

    private UpdateNews = () => {
        this.props.history.push(`/adminNews/edit/${this.state.data.id}`);
    };
    private goBack = () => {
        this.props.history.goBack();
    };
}

export const ViewNewsPage = compose(
    withRouter,
    connect(
        NewsState,
        NewsDispatch
    )
)(ViewNewsConstructor);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 66px 45px 20px 45px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: rgb(249, 245, 240);
`;
const NewsBlock = styled.div`
    font-size: 32px;
    margin-bottom: 16px;

    @media (max-width: 420px) {
        padding: 0;
        font-family: SF-UIText, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        color: #3e4c5b;
    }
`;

const Image = styled("img")`
    height: auto;
    margin-bottom: 24px;
    width: 100%;
    @media (max-width: 420px) {
        margin-bottom: 16px;
    }
`;

const Title = styled("div")`
    font-family: SFUIText, sans-serif;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #3e4c5b;
    margin-bottom: 40px;
    word-break: break-word;
    @media (max-width: 420px) {
        font-size: 20px;
        margin-bottom: 16px;
    }
`;

const FullContent = styled("div")`
    font-family: SFUIText, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e4c5b;
    margin-bottom: 46px;
    white-space: pre-line;
    @media (max-width: ${({theme}) => theme.mobileWidth}px) {
        margin-bottom: 16px;
    }
    p {
        margin: 0;
    }
    ul {
        margin: 0;
        line-height: 0.8;
    }
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px 15px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
`;
