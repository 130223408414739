import {BaseRequest} from "../../core/api/BaseRequest";
import {IAuthenticationDto} from "../../core/api/dto/AuthenticationDto.g";
import {requestsRepository} from "../../core/api/RequestRepository";
import {IThunkAction} from "../../core/store/mainReducer";
import {LoginAction} from "./loginAction";

export class LoginActionAsync {
    public static Login(login: string, password: string): IThunkAction {
        const autentification: IAuthenticationDto = {
            login,
            password
        };

        return async (dispatch) => {
            dispatch(LoginAction.login.started({params: {}}));
            try {
                const result = await requestsRepository.authorizationApiRequest.login(autentification);

                await (BaseRequest.token = result.token);

                dispatch(LoginAction.login.done({params: {}, result}));
            } catch (error) {
                dispatch(LoginAction.login.failed({params: {}, error}));
            }
        };
    }

    public static LogOut(): IThunkAction {
        return async (dispatch) => {
            dispatch(LoginAction.logOut.started({params: {}}));
            try {
                await requestsRepository.authorizationApiRequest.logOut();
                dispatch(LoginAction.logOut.done({params: {}, result: {}}));
            } catch (error) {
                dispatch(LoginAction.logOut.failed({params: {}, error}));
            }
        };
    }
}
