import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, {ChangeEvent, SyntheticEvent} from "react";
import {Editor} from "react-draft-wysiwyg";
import {IOption} from "src/components/select";
import styled, {css} from "styled-components";
import {URLbackend} from "../../../common/constants";
import {IconCross} from "../../../shared/icons/IconCross";
// import {CustomInputFile} from "./customInputFile";
import {InputFile} from "./downloadFile";
import {SelectAddNews} from "./select";

export interface IUploadFile {
    file: File;
    localSrc: string;
}

interface IProps {
    type?: "number" | "text" | "select" | "textarea" | "filePhoto" | "file" | "wysiwyg";
    // tslint:disable-next-line
    value: any;
    onChange: (value: string | File) => void;
    label?: string;
    hasError?: boolean;
    options?: IOption[];
    maxLength?: number;
    placeholder?: string;
}

interface IState {
    fileName: string | ArrayBuffer;
    editorState: EditorState;
    uploadedImages: IUploadFile[];
}

export class InputWithLabel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            fileName: "",
            editorState: EditorState.createWithContent(
                // tslint:disable-next-line
                ContentState.createFromBlockArray(htmlToDraft(props.value) as any)
            ),
            uploadedImages: []
        };
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        if (!prevProps.value && this.props.value) {
            if (this.props.type === "wysiwyg") {
                this.setState({
                    editorState: EditorState.createWithContent(
                        // tslint:disable-next-line
                        ContentState.createFromBlockArray(htmlToDraft(this.props.value) as any)
                    )
                });
            }
        }
    }

    public render() {
        const {type = "text", value, label, hasError, options = [], maxLength, placeholder} = this.props;
        const simpleField =
            type !== "select" && type !== "textarea" && type !== "filePhoto" && type !== "file" && type !== "wysiwyg";
        const isSelect = type === "select";
        const isTextarea = type === "textarea";
        const isFile = type === "filePhoto" || type === "file";
        const isWysiwyg = type === "wysiwyg";

        return (
            <InputGroup directionRow={isFile}>
                {label && !isWysiwyg && <Label hasError={hasError}>{label}</Label>}
                {simpleField && (
                    <Input
                        value={value}
                        onChange={this.onChange}
                        hasError={hasError}
                        type={type}
                        maxLength={maxLength}
                        placeholder={placeholder}
                    />
                )}
                {isFile && (
                    <>
                        {this.props.value ? (
                            <DeleteImage onClick={this.deleteImage}>
                                <IconCross />
                            </DeleteImage>
                        ) : (
                            <></>
                        )}
                        <DownloadLabel>
                            <InputFile
                                id="thefile"
                                type={"file"}
                                onChange={this.DownloadFile}
                                accept={type === "filePhoto" ? ".jpg, .png, .svg" : ""}
                            />
                            {this.props.value ? (
                                <img
                                    src={
                                        this.state.fileName.toString()
                                            ? this.state.fileName.toString()
                                            : URLbackend + "/" + this.props.value
                                    }
                                    alt={""}
                                />
                            ) : (
                                "Нажмите для выбора изображения"
                            )}
                        </DownloadLabel>
                    </>
                )}
                {isSelect && <SelectAddNews value={value} onChange={this.onSelectChange} options={options} />}
                {isTextarea && (
                    <Textarea
                        value={value}
                        onChange={this.onChangeTextarea}
                        hasError={hasError}
                        maxLength={maxLength}
                    />
                )}
                {isWysiwyg && (
                    <Editor
                        editorState={this.state.editorState}
                        editorStyle={{
                            color: "#000000",
                            fontSize: 20,
                            overflow: "hidden",
                            border: "1px solid #9EA7B0",
                            boxSizing: "border-box",
                            borderRadius: "4px",
                            padding: "8px",
                            height: "auto",
                            background: "rgb(249, 245, 240)"
                        }}
                        toolbarClassName="toolbarClassName"
                        toolbarStyle={{
                            color: "#000"
                        }}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onWysiwyg}
                    />
                )}
            </InputGroup>
        );
    }

    private deleteImage = () => {
        this.props.onChange("");
    };

    private DownloadFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const {onChange} = this.props;

        const reader: FileReader = new FileReader();

        if (event.target.files !== null) {
            reader.onload = (e: Event) => {
                if (e !== null) {
                    if (reader.result !== null) {
                        this.setState({fileName: reader.result});
                    }
                }
            };
            reader.readAsDataURL(event.target.files[0]);

            onChange(event.target.files[0]);
        }
    };

    private onChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const {onChange} = this.props;
        onChange(event.currentTarget.value);
    };

    private onChangeTextarea = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        const {onChange} = this.props;
        onChange(event.currentTarget.value);
    };

    private onSelectChange = (value: string) => {
        const {onChange} = this.props;
        onChange(value);
    };

    private onWysiwyg = (value: EditorState) => {
        this.setState({editorState: value}, () => {
            const {onChange} = this.props;
            onChange(draftToHtml(convertToRaw(value.getCurrentContent())));
        });
    };
}

const InputGroup = styled.div<{directionRow: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.directionRow ? "space-between" : "start")};
    width: 100%;
`;

const Textarea = styled<{hasError?: boolean}, "textarea">("textarea")`
    flex: 1;
    height: 128px;
    resize: none;
    border: 1px solid #9ea7b0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: ${(props) => (props.hasError ? props.theme.colors.errorBackground : props.theme.colors.white)};
    width: 100%;
    padding: 5px 20px;
    margin-bottom: 16px;
    outline: none;
    ${(props) =>
        props.hasError &&
        css`
            caret-color: ${props.theme.colors.white};
            color: ${props.theme.colors.white};
        `};
    transition: background-color 0.5s ease;

    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #000000;
`;

const Input = styled<{hasError?: boolean; withCheckbox?: boolean}, "input">("input")`
    ${(props) =>
        !props.withCheckbox &&
        css`
            width: 100%;
        `};
    ${(props) =>
        props.withCheckbox &&
        css`
            flex: 1;
        `};
    height: 48px;
    border-radius: 4px;
    border: 1px solid #9ea7b0;
    background-color: ${(props) => (props.hasError ? props.theme.colors.errorBackground : props.theme.colors.white)};
    outline: none;
    padding: 5px 20px;
    margin-bottom: 16px;
    ${(props) =>
        props.hasError &&
        css`
            caret-color: ${props.theme.colors.white};
            color: ${props.theme.colors.white};
        `};
    transition: background-color 0.5s ease;

    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #000000;
`;

const Label = styled.div<{hasError?: boolean}>`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    margin-bottom: 8px;
    color: ${(props) => (props.hasError ? props.theme.colors.errorBackground : "#000000")};
`;

const DownloadLabel = styled.label`
    border: 1px solid #9ea7b0;
    box-sizing: border-box;

    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    margin-bottom: 16px;
    height: 100%;
    width: 100%;
    color: #9ea7b0;
    justify-content: center;
    align-items: center;
    display: flex;
    img {
        max-width: 400px;
        max-height: 400px;
    }
`;

const DeleteImage = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
`;
