import React from "react";

export const IconOpenEye = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M1 8.33333C1 8.33333 3.66667 3 8.33333 3C13 3 15.6667 8.33333 15.6667 8.33333C15.6667 8.33333 13 13.6667 8.33333 13.6667C3.66667 13.6667 1 8.33333 1 8.33333Z"
                stroke="#384552"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.33337 10.3333C9.43794 10.3333 10.3334 9.43788 10.3334 8.33331C10.3334 7.22874 9.43794 6.33331 8.33337 6.33331C7.2288 6.33331 6.33337 7.22874 6.33337 8.33331C6.33337 9.43788 7.2288 10.3333 8.33337 10.3333Z"
                stroke="#384552"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
