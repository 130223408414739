export const required = (value: string) => (value ? undefined : "Заполните поле");

export const validatePreviewLine = () => {
    Object.keys(ArrayFormAuthorisationForm).map((name) => {
        ArrayFormAuthorisationForm[name].showError = true;
    });

    return ArrayFormAuthorisationForm;
};

export const ArrayFormAuthorisationForm = {
    login: {
        id: 1,
        showError: false,
        name: "title"
    },
    password: {
        id: 2,
        showError: false,
        name: "cityId"
    }
};
