import {AuthorizationApiRequest} from "./AuthorizationApiRequest.g";
import {CalendarApiRequest} from "./CalendarApiRequest.g";
import {CategoryApiRequest} from "./CategoryApiRequest.g";
import {CityApiRequest} from "./CityApiRequest.g";
import {NewsWitUploadApiRequest} from "./NewsWitUploadApiRequest.g";

export class RequestsRepository {
    public authorizationApiRequest = new AuthorizationApiRequest();
    public calendarApiRequest = new CalendarApiRequest();
    public categoryApiRequest = new CategoryApiRequest();
    public cityApiRequest = new CityApiRequest();
    public newsApiRequest = new NewsWitUploadApiRequest();
}

export const requestsRepository = new RequestsRepository();
