import {URLbackend} from "../../common/constants";
import {ICity} from "./dto/City.g";
import {BaseRequest} from "./BaseRequest";

export class CityApiRequest extends BaseRequest {
    constructor() {
        super();
        this.baseurl = URLbackend;
    }
    public getAll(config?: object): Promise<ICity[]> {
        return this.fetch(
            `/allApi/City`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }
}
