import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import styled from "styled-components";
import {FormAddNews} from "./newsPage/formAddNews";
import {NewsDispatch, NewsState} from "./newsPage/selectors";

interface IProps
    extends ReturnType<typeof NewsDispatch>,
        ReturnType<typeof NewsState>,
        RouteComponentProps<{id: string}> {}

class AddNewsConstructor extends React.Component<IProps> {
    public componentDidMount() {
        if (this.props.match.params.id) {
            this.props.initialNewsById(this.props.match.params.id);
        }
        this.props.initialCategory();
        this.props.initialCity();
    }

    public render() {
        const {data} = this.props;

        return (
            <Container>
                <FormAddNews
                    // @ts-ignore
                    dataNews={data && data.items && data.items.find((item) => item.id === this.props.match.params.id)}
                    id={this.props.match.params.id}
                />
            </Container>
        );
    }
}

export const AddNewsPage = compose(
    withRouter,
    connect(
        NewsState,
        NewsDispatch
    )
)(AddNewsConstructor);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 66px 45px 20px 45px;
    width: 100%;
    justify-content: center;
`;
