import {URLbackend} from "../../common/constants";
import {BaseRequest} from "./BaseRequest";
import {IAuthenticationDto} from "./dto/AuthenticationDto.g";
import {ITokenResponse} from "./dto/TokenResponse.g";

export class AuthorizationApiRequest extends BaseRequest {
    constructor() {
        super();
        this.baseurl = URLbackend;
    }

    public login(authentication: IAuthenticationDto, config?: object): Promise<ITokenResponse> {
        return this.fetch(
            `/allApi/Authorization/login`,
            Object.assign(
                {
                    method: "POST",
                    body: JSON.stringify(authentication)
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }

    public logOut(config?: object): Promise<void> {
        return this.fetch(
            `/allApi/Authorization/logout`,
            Object.assign(
                {
                    method: "POST"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }

    public checkAuthorize(config?: object): Promise<void> {
        return this.fetch(
            `/allApi/Authorization/CheckAuthorize`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }
}
