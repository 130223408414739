import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import styled from "styled-components";
import {RedButton} from "../ui/RedButton";
import {LoginPageDispatch} from "./loginPage/selector";

interface IProps extends ReturnType<typeof LoginPageDispatch>, RouteComponentProps {}

interface IState {
    hoverNews: boolean;
    hoverCalendar: boolean;
}

class HeaderConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {hoverCalendar: false, hoverNews: false};
    }

    public render() {
        return (
            <>
                {this.props.history.location.pathname !== "/Login" ? (
                    <Container>
                        <LogOut onClick={this.logOut}>Выход</LogOut>
                    </Container>
                ) : (
                    <></>
                )}
            </>
        );
    }
    private logOut = () => {
        this.props.logOut();
        this.props.history.push("/Login");
    };
}

export const Header = withRouter(
    connect(
        null,
        LoginPageDispatch
    )(HeaderConstructor)
);

const Container = styled("div")`
    background: #ffffff;
    height: 46px;
    left: 200px;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    z-index: 999;
`;
const LogOut = styled(RedButton)`
    margin-right: 60px;
`;
