import * as moment from "moment";
import React from "react";
import {IconPaginationCalendar} from "src/shared/icons/IconPaginationCalendar";
import styled from "styled-components";

interface IProps {
    month: moment.Moment;
    prevMonth?: () => void;
    nextMonth?: () => void;
}

export class PaginationCalendar extends React.Component<IProps> {
    public render() {
        return (
            <Container>
                {moment(this.props.month).format("MMMM") + " " + moment(this.props.month).year()}
                <ButtonLeft onClick={this.props.prevMonth}>
                    <IconPaginationCalendar />
                </ButtonLeft>
                <ButtonRigth onClick={this.props.nextMonth}>
                    <IconPaginationCalendar />
                </ButtonRigth>
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: normal;
    color: #000000;
    background: #ffffff;
    border-radius: 4px;
    justify-content: flex-end;
    padding: 15px 40px;
`;
export const ButtonLeft = styled.div`
    cursor: pointer;
    background: #fff;
    border-radius: 8px 0 0 8px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 34px;
`;
export const ButtonRigth = styled.div`
    cursor: pointer;
    background: #fff;
    border-radius: 8px 0 0 8px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 49px;
    transform: rotate(180deg);
`;
