import React from "react";
import {styled} from "../../core/styledComponents";

const Container = styled("div")`
    display: flex;
    justify-content: space-between;
`;

export class CalendarRow extends React.Component {
    public render(): JSX.Element {
        return <Container>{this.props.children}</Container>;
    }
}
