import React, {SyntheticEvent} from "react";
import styled from "styled-components";
import {IconCloseEye} from "../../../shared/icons/IconCloseEye";
import {IconOpenEye} from "../../../shared/icons/IconOpenEye";

interface IProps {
    type?: "text" | "password";
    // tslint:disable-next-line
    value: any;
    onChange: (value: string) => void;
    hasError?: boolean;
    maxLength?: number;
    placeholder?: string;
}

interface IState {
    fileName: string;
    typePassword: "password" | "text";
}

export class InputWithLabel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {fileName: "", typePassword: "password"};
    }

    public render() {
        const {type = "text", value, hasError, maxLength, placeholder} = this.props;
        const simpleField = type !== "password";
        const isPassword = type === "password";

        return (
            <InputGroup>
                {simpleField && (
                    <>
                        <Lable>Логин</Lable>
                        <Input
                            value={value}
                            onChange={this.onChange}
                            hasError={hasError}
                            type={type}
                            maxLength={maxLength}
                            placeholder={placeholder}
                        />
                    </>
                )}

                {isPassword && (
                    <>
                        <Lable>Пароль</Lable>
                        <Input
                            placeholder={placeholder}
                            hasError={hasError}
                            type={this.state.typePassword}
                            value={value}
                            onChange={this.onChange}
                        />
                        <IconContainer onClick={this.onMouseClick}>
                            {this.state.typePassword === "password" ? <IconCloseEye /> : <IconOpenEye />}
                        </IconContainer>
                    </>
                )}
            </InputGroup>
        );
    }

    private onChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const {onChange} = this.props;
        onChange(event.currentTarget.value);
    };
    private onMouseClick = () => {
        this.setState({typePassword: this.state.typePassword === "password" ? "text" : "password"});
    };
}

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const Input = styled<{hasError?: boolean; withCheckbox?: boolean}, "input">("input")`
    width: 100%;
    outline: none;
    padding: 8px 8px;
    margin-bottom: 10px;
    border: 1px solid #9ea7b0;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #384552;
`;
export const Lable = styled.label`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: #9ea7b0;
`;
const IconContainer = styled.div`
    position: absolute;
    right: 0;
    top: 18px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
