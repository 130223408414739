import React from "react";

export const IconLupa = () => (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.7143 0C37.595 0 45.153 3.1306 50.7255 8.70311C56.298 14.2756 59.4286 21.8336 59.4286 29.7143C59.4286 37.0743 56.7314 43.84 52.2971 49.0514L53.5314 50.2857H57.1429L80 73.1429L73.1429 80L50.2857 57.1429V53.5314L49.0514 52.2971C43.84 56.7314 37.0743 59.4286 29.7143 59.4286C21.8336 59.4286 14.2756 56.298 8.70311 50.7255C3.1306 45.153 0 37.595 0 29.7143C0 21.8336 3.1306 14.2756 8.70311 8.70311C14.2756 3.1306 21.8336 0 29.7143 0ZM29.7143 9.14286C18.2857 9.14286 9.14286 18.2857 9.14286 29.7143C9.14286 41.1429 18.2857 50.2857 29.7143 50.2857C41.1429 50.2857 50.2857 41.1429 50.2857 29.7143C50.2857 18.2857 41.1429 9.14286 29.7143 9.14286Z"
            fill="#EAEAEA"
        />
    </svg>
);
