/* tslint:disable:prefer-for-of */
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {EnumCalendarDayTypes} from "src/common/enums/calendarDayTypes";
import {CalendarCell} from "./CalendarCell";
import {CalendarRow} from "./CalendarRow";
import {CalendarState} from "./selectors";

interface IProps extends ReturnType<typeof CalendarState> {
    startDay: moment.Moment;
    week: number;
    onClick?: (day: moment.Moment) => void;
}

interface IState {
    visibleTooltips: boolean;
    //     descriptionTooltips?: string;
}

class DaysConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {visibleTooltips: false};
    }

    public async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.loading === true) {
            await this.setState({visibleTooltips: !this.state.visibleTooltips});
        }
    }

    public render() {
        const {startDay, week, onClick} = this.props;
        const startDayIndex = startDay.day() === 0 ? 7 : startDay.day();
        const days = Array(7)
            .fill(1)
            .map((item, index) => startDay.clone().add(7 * week + index - startDayIndex + 1, "days"));

        const daysComponents = days.map((day) => {
            const data = this.props.calendarData.filter(
                (value) => moment(value.date).format("DD.MM.YY") === day.format("DD.MM.YY")
            );

            return (
                <CalendarCell
                    key={day.format("D")}
                    value={day.format("D")}
                    onClick={onClick}
                    dayType={this.getDayType(day, startDay)}
                    day={day}
                    description={data && data.length !== 0 ? data[0].description : undefined}
                />
            );
        });

        if (
            days[0].toDate() <=
            startDay
                .clone()
                .endOf("month")
                .toDate()
        ) {
            return (
                <>
                    <CalendarRow>{daysComponents}</CalendarRow>
                </>
            );
        } else {
            return null;
        }
    }

    private getDayType = (day: moment.Moment, startDay: moment.Moment): EnumCalendarDayTypes => {
        if (day.month() !== startDay.month()) {
            return EnumCalendarDayTypes.Inactive;
        }
        if (this.props.loading) {
            return EnumCalendarDayTypes.Standard;
        }
        for (let i = 0; i < this.props.calendarData.length; i++) {
            if (day.date() === moment(this.props.calendarData[i].date).date()) {
                return EnumCalendarDayTypes.Holiday;
            }
        }
        return EnumCalendarDayTypes.Standard;
    };
}

export const Days = connect(CalendarState)(DaysConstructor);
