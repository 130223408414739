import styled from "styled-components";

export const WhiteButton = styled.button`
    background: #ffffff;
    border: solid 1px #1963e2;
    color: #384552;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    outline: none;
    padding: 6px 23px;
    margin-right: 30px;
    position: relative;
    transition: all 0.1s ease-in-out;

    :hover {
        background-color: #1963e2;
        color: #ffffff;
    }

    :active {
        background-color: #134db0;
        color: #ffffff;
    }
`;
