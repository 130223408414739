import arrayMutators from "final-form-arrays";
import React from "react";
import {Field, Form, FormRenderProps} from "react-final-form";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import styled from "styled-components";
import {IAuthenticationDto} from "../../core/api/dto/AuthenticationDto.g";
import {BlueButton} from "../../ui/BlueButton";
import {InputWithLabel} from "./component/inputWuthLabel";
import {LoginPageDispatch, LoginPageState} from "./selector";
import {required} from "./validation";

interface IProps extends ReturnType<typeof LoginPageDispatch>, ReturnType<typeof LoginPageState>, RouteComponentProps {}

interface IState {
    errorTitle: boolean;
}

class AuthorisationFormConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {errorTitle: false};
    }

    public render() {
        return (
            <Container>
                <Title>Вход</Title>
                <Form onSubmit={this.onSubmit} mutators={{...arrayMutators}} render={this.renderForm} />
            </Container>
        );
    }

    private renderForm = (props: FormRenderProps): React.ReactNode => {
        const {
            handleSubmit,
            form: {}
        } = props;

        return (
            <form onSubmit={handleSubmit}>
                <Field name="login" validate={required}>
                    {({input, meta}) => <InputWithLabel {...input} maxLength={100} />}
                </Field>
                <Field name={"password"} validate={required}>
                    {({input, meta}) => <InputWithLabel {...input} type={"password"} maxLength={100} />}
                </Field>
                <ErrorTitle visible={this.state.errorTitle}>логин или пароль неверны</ErrorTitle>
                <ButtonContainer>
                    <Button type="submit">Войти</Button>
                </ButtonContainer>
            </form>
        );
    };
    private onSubmit = async (value: IAuthenticationDto) => {
        this.props.getLogin(value.login, value.password);
        setTimeout(() => this.setState({errorTitle: true}), 5000);
    };
}

export const AuthorisationForm = withRouter(
    connect(
        LoginPageState,
        LoginPageDispatch
    )(AuthorisationFormConstructor)
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 33px 48px;
    background: #ffffff;
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #384552;
    margin-bottom: 32px;
    text-align: center;
`;
const Button = styled(BlueButton)`
    border-radius: 8px;
    padding: 10px 24px;
    width: 100%;
    margin: 0;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const ErrorTitle = styled.div<{visible: boolean}>`
    opacity: ${(props) => (props.visible ? "1" : "0")};
    font-size: 12px;
    color: #ff001f;
    margin-bottom: 10px;
    text-align: center;
`;
