import {reducerWithInitialState} from "typescript-fsa-reducers";
import {TooltipsAction} from "./tooltipsAction";

interface ICategoryState {
    text: string;
}

const CategoryState: ICategoryState = {
    text: ""
};

export const TooltipsReducer = reducerWithInitialState(CategoryState)
    .case(TooltipsAction.showTooltips, (state, payload) => ({text: payload}))

    .case(TooltipsAction.closeTooltips, () => ({text: ""}));
