export const required = (value: string) => (value ? undefined : "Заполните поле");

export const validatePreviewLine = () => {
    Object.keys(ArrayFormAddNews).map((name) => {
        ArrayFormAddNews[name].showError = true;
    });

    return ArrayFormAddNews;
};

export const validateSuccessful = () => {
    Object.keys(ArrayFormAddNews).map((name) => {
        ArrayFormAddNews[name].showError = false;
    });

    return ArrayFormAddNews;
};

export const ArrayFormAddNews = {
    title: {
        id: 1,
        showError: false,
        name: "title",
        label: "Заголовок новости:"
    },
    cityId: {
        id: 2,
        showError: false,
        name: "cityId",
        label: "Город:"
    },
    categoryId: {
        id: 3,
        showError: false,
        name: "categoryId",
        label: "Категория:"
    },
    description: {
        id: 4,
        showError: false,
        name: "description",
        label: "Текст новости:"
    },
    filePuth: {
        id: 5,
        showError: false,
        name: "description",
        label: "Добавить файл"
    },
    filePhoto: {
        id: 6,
        showError: false,
        name: "description",
        label: "Добавить картинку"
    }
};

export const ArrayFormAddFormCalendar = {
    type: {
        id: 1,
        showError: false,
        name: "type",
        label: "Тип:"
    },
    description: {
        id: 4,
        showError: false,
        name: "description",
        label: "Описание:"
    }
};

export const validatePreviewLineAddFormCalendar = () => {
    Object.keys(ArrayFormAddFormCalendar).map((name) => {
        ArrayFormAddFormCalendar[name].showError = true;
    });

    return ArrayFormAddFormCalendar;
};
export const validatePassAddFormCalendar = () => {
    Object.keys(ArrayFormAddFormCalendar).map((name) => {
        ArrayFormAddFormCalendar[name].showError = false;
    });

    return ArrayFormAddFormCalendar;
};
