import {requestsRepository} from "../../core/api/RequestRepository";
import {IThunkAction} from "../../core/store/mainReducer";
import {CityAction} from "./cityAction";

export class CityActionAsync {
    public static LoadingCity(): IThunkAction {
        return async (dispatch) => {
            dispatch(CityAction.getAll.started({params: {}}));
            try {
                const result = await requestsRepository.cityApiRequest.getAll();
                result.push({id: -1, title: "Без города", zip: ""});
                dispatch(CityAction.getAll.done({params: {}, result}));
            } catch (error) {
                dispatch(CityAction.getAll.failed({params: {}, error}));
            }
        };
    }
}
