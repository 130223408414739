import {reducerWithInitialState} from "typescript-fsa-reducers";
import {ICategory} from "../../core/api/dto/Category.g";
import {CategoryAction} from "./categoryAction";

interface ICategoryState {
    data: ICategory[];
    loading: boolean;
}

const CategoryState: ICategoryState = {
    data: [],
    loading: false
};

export const CategoryReducer = reducerWithInitialState(CategoryState)
    .case(CategoryAction.getRoot.started, (state) => ({data: state.data, loading: true}))
    .case(CategoryAction.getRoot.done, (state, payload) => ({data: payload.result, loading: false}))
    .case(CategoryAction.getRoot.failed, (state, payload) => ({data: state.data, loading: false}))

    .case(CategoryAction.add.started, (state) => ({data: state.data, loading: true}))
    .case(CategoryAction.add.done, (state, payload) => ({data: state.data, loading: false}))
    .case(CategoryAction.add.failed, (state, payload) => ({data: state.data, loading: false}))

    .case(CategoryAction.update.started, (state) => ({data: state.data, loading: true}))
    .case(CategoryAction.update.done, (state, payload) => ({data: state.data, loading: false}))
    .case(CategoryAction.update.failed, (state, payload) => ({data: state.data, loading: false}))

    .case(CategoryAction.delete.started, (state) => ({data: state.data, loading: true}))
    .case(CategoryAction.delete.done, (state) => ({data: state.data, loading: false}))
    .case(CategoryAction.delete.failed, (state, payload) => ({data: state.data, loading: false}));
