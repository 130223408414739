import React from "react";

export const IconDelete = () => (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 1.22222H12.75L11.5357 0H5.46429L4.25 1.22222H0V3.66667H17V1.22222ZM1.21429 19.5556C1.21429 20.2039 1.47015 20.8256 1.9256 21.284C2.38104 21.7425 2.99876 22 3.64286 22H13.3571C14.0012 22 14.619 21.7425 15.0744 21.284C15.5298 20.8256 15.7857 20.2039 15.7857 19.5556V4.88889H1.21429V19.5556Z"
            fill="#EF597D"
        />
    </svg>
);
