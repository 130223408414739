import {URLbackend} from "../../common/constants";
import {ICategory} from "./dto/Category.g";
import {ICategoryShort} from "./dto/CategoryShort.g";
import {IPageResult} from "./dto/PageResult.g";
import {BaseRequest} from "./BaseRequest";

export class CategoryApiRequest extends BaseRequest {
    constructor() {
        super();
        this.baseurl = URLbackend;
    }
    public add(category: ICategoryShort, config?: object): Promise<string> {
        return this.fetch(
            `/allApi/admin/Category`,
            Object.assign(
                {
                    method: "POST",
                    body: JSON.stringify(category)
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }
    public getRoot(config?: object): Promise<ICategory[]> {
        return this.fetch(
            `/allApi/admin/Category/GetRoot`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }
    public getByPage(pageNumber: number, pageSize: number, config?: object): Promise<IPageResult<ICategory>> {
        return this.fetch(
            `/allApi/admin/Category/${pageNumber}/${pageSize}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }
    public getSub(id: string, config?: object): Promise<ICategory[]> {
        return this.fetch(
            `/allApi/admin/Category/GetSub/${id}`,
            Object.assign(
                {
                    method: "GET"
                },
                config
            )
        )
            .then((response) => response.json())
            .catch(BaseRequest.handleError);
    }
    public update(id: string, category: ICategoryShort, config?: object): Promise<void> {
        return this.fetch(
            `/allApi/admin/Category/${id}`,
            Object.assign(
                {
                    method: "PUT",
                    body: JSON.stringify(category)
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }
    public delete(id: string, config?: object): Promise<void> {
        return this.fetch(
            `/allApi/admin/Category/${id}`,
            Object.assign(
                {
                    method: "DELETE"
                },
                config
            )
        ).catch(BaseRequest.handleError);
    }
}
