import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {IconCalendar} from "src/shared/icons/IconCalendar";
import {IconMailBox} from "src/shared/icons/IconMenuNews";
import styled from "styled-components";
import {StyledNavLink} from "./leftMenu/StyledNavLink";
import {LoginPageDispatch} from "./loginPage/selector";

const activeStyle = {
    background: "#1C6FFC",
    color: "white"
};

interface IProps extends ReturnType<typeof LoginPageDispatch>, RouteComponentProps {}

interface IState {
    hoverNews: boolean;
    hoverCalendar: boolean;
}

class LeftMenuConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {hoverCalendar: false, hoverNews: false};
    }

    public render() {
        return (
            <>
                {this.props.history.location.pathname !== "/Login" ? (
                    <Container>
                        <Title>PMR.MD</Title>
                        <MenuItems>
                            <TitleNavLink>
                                <IconMailBox />
                                <Text>Новости</Text>
                            </TitleNavLink>
                            <StyledNavLink to={"/adminNews"} activeStyle={activeStyle}>
                                Все новости
                            </StyledNavLink>
                            <StyledNavLink to={"/adminCategory"} activeStyle={activeStyle}>
                                Категории
                            </StyledNavLink>

                            <TitleNavLink>
                                <IconCalendar />
                                <Text>Календарь</Text>
                            </TitleNavLink>
                            <StyledNavLink to={"/adminCalendar"} activeStyle={activeStyle}>
                                Редактирование
                            </StyledNavLink>
                            <StyledNavLink to={"/adminAddFileCalendar"} activeStyle={activeStyle}>
                                Загрузка
                            </StyledNavLink>
                        </MenuItems>
                    </Container>
                ) : (
                    <></>
                )}
            </>
        );
    }
}

export const LeftMenu = withRouter(
    connect(
        null,
        LoginPageDispatch
    )(LeftMenuConstructor)
);

const Container = styled("div")`
    background: linear-gradient(180deg, #ffffff 4.71%, #f8f4ee 20.69%);
    width: 200px;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 999;
`;
const Title = styled("div")`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: normal;
    text-align: center;
    color: #000000;
    margin-top: 8px;
`;
const MenuItems = styled("div")`
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    width: 100%;
`;
const Text = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #384552;
    margin-left: 11px;
`;
const TitleNavLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 19px;
    width: 100%;
    user-select: none;
`;
