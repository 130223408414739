import {createSelector} from "reselect";
import {ICalendarDateShort} from "src/core/api/dto/CalendarDateShort.g";
import {IAppDispatch, IAppState} from "src/core/store/mainReducer";
import {CalendarCelebrateData} from "src/modules/calendar/calendarActionAsync";

export const CalendarState = createSelector(
    (state: IAppState) => state.calendarReduser.data,
    (state: IAppState) => state.calendarReduser.loading,
    (calendarData, loading) => ({calendarData, loading})
);

export const CalendarDispatch = (dispatch: IAppDispatch) => ({
    initialCalendar: (month: number, year: number) => dispatch(CalendarCelebrateData.LoadItem(month, year)),
    fillYearWeekend: (year: number) => dispatch(CalendarCelebrateData.FillYearWeekend(year)),
    transfer: (fromYear: number, toYear: number) => dispatch(CalendarCelebrateData.TransferHoliday(fromYear, toYear))
});

export const CalendarAddFormDispatch = (dispatch: IAppDispatch) => ({
    create: (date: ICalendarDateShort) => dispatch(CalendarCelebrateData.CreateCelebrate(date)),
    update: (id: string, date: ICalendarDateShort) => dispatch(CalendarCelebrateData.UpdateCelebrate(id, date)),
    delete: (id: string) => dispatch(CalendarCelebrateData.DeleteCelebrate(id)),
    initialCalendar: (month: number, year: number) => dispatch(CalendarCelebrateData.LoadItem(month, year))
});
