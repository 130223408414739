import React from "react";

export const IconCapral = () => (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3408 2.39086L11.8242 0.854492L6.84082 5.90254L1.85749 0.854492L0.34082 2.39086L6.84082 8.97527L13.3408 2.39086Z"
            fill="#9EA7B0"
        />
    </svg>
);
