import React from "react";

export const IconMailBox = () => (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.75 22C16.3467 22 16.919 21.7682 17.341 21.3556C17.7629 20.9431 18 20.3835 18 19.8V2.2C18 0.979 16.9875 0 15.75 0H9V7.7L6.1875 6.05L3.375 7.7V0H2.25C1.65326 0 1.08097 0.231785 0.65901 0.644365C0.237053 1.05694 0 1.61652 0 2.2V19.8C0 20.3835 0.237053 20.9431 0.65901 21.3556C1.08097 21.7682 1.65326 22 2.25 22H15.75Z"
            fill="#384552"
        />
    </svg>
);
