import {ICalendarDate} from "../../core/api/dto/CalendarDate.g";
import {ICalendarDateShort} from "../../core/api/dto/CalendarDateShort.g";
import {actionCreator} from "../../core/store/common/actionCreator";

export class CalendarActions {
    public static getByMonth = actionCreator.async<IEmpty, ICalendarDate[], Error>("Calendar/GET_BY_MONTH");
    public static create = actionCreator.async<ICalendarDateShort, string, Error>("Calendar/CREATE");
    public static update = actionCreator.async<IUpdateCalendarParams, IEmpty, Error>("Calendar/UPDATE");
    public static delete = actionCreator.async<string, IEmpty, Error>("Calendar/DELETE");
    // public static fillYearWeekend = actionCreator.async<number, IEmpty, Error>("Calendar/FILL_YEAR_WEEKEND");
}

export interface IUpdateCalendarParams {
    id: string;
    date: ICalendarDateShort;
}
