import {createSelector} from "reselect";
import {IAppDispatch, IAppState} from "src/core/store/mainReducer";
import {CategoryActionAsync} from "src/modules/category/categoryActionAsync";

export const CategoryState = createSelector(
    (state: IAppState) => state.categoryReduser.data,
    (state: IAppState) => state.categoryReduser.loading,
    (data, loading) => ({data, loading})
);

export const CategoryDispatch = (dispatch: IAppDispatch) => ({
    create: (name: string) => dispatch(CategoryActionAsync.Create(name)),
    delete: (id: string) => dispatch(CategoryActionAsync.Delete(id)),
    update: (id: string, name: string) => dispatch(CategoryActionAsync.Update(id, name)),
    initialCategory: () => dispatch(CategoryActionAsync.LoadingCategory())
});
