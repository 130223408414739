import React from "react";

export const IconCloseEye = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M9.74667 9.74668C9.56357 9.94318 9.34277 10.1008 9.09743 10.2101C8.8521 10.3194 8.58727 10.3782 8.31873 10.3829C8.05019 10.3877 7.78344 10.3383 7.53441 10.2377C7.28537 10.1371 7.05915 9.98737 6.86923 9.79745C6.67931 9.60754 6.52959 9.38131 6.42901 9.13228C6.32842 8.88324 6.27902 8.6165 6.28376 8.34796C6.28849 8.07942 6.34727 7.81458 6.45658 7.56925C6.5659 7.32392 6.7235 7.10312 6.92 6.92002M12.2933 12.2933C11.1537 13.162 9.76607 13.6433 8.33333 13.6667C3.66667 13.6667 1 8.33335 1 8.33335C1.82926 6.78795 2.97942 5.43775 4.37333 4.37335L12.2933 12.2933ZM6.93333 3.16002C7.39222 3.0526 7.86204 2.99891 8.33333 3.00002C13 3.00002 15.6667 8.33335 15.6667 8.33335C15.262 9.09042 14.7794 9.80317 14.2267 10.46L6.93333 3.16002Z"
                stroke="#384552"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1 1L15.6667 15.6667" stroke="#384552" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
