import React, {PureComponent} from "react";
import {Field} from "react-final-form";
import {IOption} from "src/components/select";
import {InputWithLabel} from "./inputWithLabel";

interface IProps {
    name: string;
    hasError?: boolean;
    validate?: (value: string) => void;
    type?: "text" | "number" | "select" | "textarea" | "file" | "filePhoto" | "wysiwyg";
    options?: IOption[];
    maxLength?: number;
    arrayForm: object;
}

export class InputFieldWrapper extends PureComponent<IProps> {
    public render(): JSX.Element {
        return (
            <Field name={this.props.name} validate={this.props.validate}>
                {({input, meta}) => (
                    <InputWithLabel
                        {...input}
                        label={this.props.arrayForm[this.props.name].label}
                        hasError={this.props.hasError && !!this.props.validate ? meta.error : undefined}
                        type={this.props.type}
                        options={this.props.options}
                        maxLength={this.props.maxLength}
                    />
                )}
            </Field>
        );
    }
}
