import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {BlueButton} from "../ui/BlueButton";
import {OutsideClick} from "../ui/OutsideClick";
import {SmallWindow, SmallWindowDescription, SmallWindowTitle} from "../ui/SmallWindow";
import {TooltipsDispatch, TooltipsState} from "./tooltips/selectors";

interface IProps extends ReturnType<typeof TooltipsState>, ReturnType<typeof TooltipsDispatch> {}

class ScreenTooltips extends React.Component<IProps> {
    public render() {
        return (
            <>
                <OutsideClick
                    visible={!!(this.props.text && this.props.text.length !== 0)}
                    onClick={this.closeTooltips}
                />
                <ContainerTooltips active={!!(this.props.text && this.props.text.length !== 0)}>
                    <SmallWindowTitle>Внимание</SmallWindowTitle>
                    <SmallWindowDescription>{this.props.text}</SmallWindowDescription>
                    <BlueButton onClick={this.closeTooltips}>Вернуться</BlueButton>
                </ContainerTooltips>
            </>
        );
    }

    private closeTooltips = async () => {
        this.props.closeTooltips();
    };
}

export const Tooltips = connect(
    TooltipsState,
    TooltipsDispatch
)(ScreenTooltips);

const ContainerTooltips = styled(SmallWindow)`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    max-width: 450px;
`;
