import {createSelector} from "reselect";
import {INewsShort} from "src/core/api/dto/NewsShort.g";
import {ISortParamDto} from "src/core/api/dto/SortParamDto.g";
import {IAppDispatch, IAppState} from "src/core/store/mainReducer";
import {CategoryActionAsync} from "src/modules/category/categoryActionAsync";
import {CityActionAsync} from "src/modules/city/cityActionAsync";
import {NewsDataActionAsync} from "src/modules/news/newsActionAsync";
import {URLbackend} from "../../common/constants";
import {TooltipsAction} from "../../modules/tooltips/tooltipsAction";

export const NewsState = createSelector(
    (state: IAppState) => state.newsReduser.data,
    (state: IAppState) => state.newsReduser.loading,
    (data, loading) => ({data, loading})
);

export const NewsDispatch = (dispatch: IAppDispatch) => ({
    initialNews: (pageNumber: number) => dispatch(NewsDataActionAsync.SortNews(pageNumber)),
    initialNewsById: (id: string) => dispatch(NewsDataActionAsync.InitialNewsById(id)),
    initialCategory: () => dispatch(CategoryActionAsync.LoadingCategory()),
    initialCity: () => dispatch(CityActionAsync.LoadingCity()),
    updateCategoryReduser: (value: string) => dispatch(NewsDataActionAsync.AddParamCategorySortByReduser(value)),
    updateTitleReduser: (value: string) => dispatch(NewsDataActionAsync.AddParamTitleSortByReducer(value)),
    publicate: (id: string, page: number) => dispatch(NewsDataActionAsync.Publicate(id, page)),
    unPublicate: (id: string, page: number) => dispatch(NewsDataActionAsync.UnPublicate(id, page))
});

export const AddNewsState = createSelector(
    (state: IAppState) => state.categoryReduser.data,
    (state: IAppState) => state.cityReduser.data,
    (dataCategiryList, dataCityList) => {
        const dataCategory = dataCategiryList.map((item) => ({value: item.id, label: item.name}));
        const dataCity = dataCityList.map((item) => ({value: item.id, label: item.title}));
        return {dataCategory, dataCity};
    }
);

export const AddNewsDispatch = (dispatch: IAppDispatch) => ({
    addNews: (file: File, image: File, news: INewsShort) => dispatch(NewsDataActionAsync.AddNews(file, image, news)),
    updateNews: (id: string, file: File, image: File, news: INewsShort) =>
        dispatch(NewsDataActionAsync.UpdateNews(id, file, image, news)),
    initialNews: (pageNumber: number) => dispatch(NewsDataActionAsync.SortNews(pageNumber)),
    deleteNews: (id: string) => dispatch(NewsDataActionAsync.DeleteNews(id)),
    updateFilterReduser: (value: ISortParamDto) => dispatch(NewsDataActionAsync.AddParamFilterSortByReduser(value)),
    showTooltips: (text: string) => dispatch(TooltipsAction.showTooltips(text)),
    closeTooltips: () => dispatch(TooltipsAction.closeTooltips(""))
});

type resolveType = (something: string | ArrayBuffer | null) => void;

export async function loadFileFromServer(filepath: string) {
    return new Promise((resolve: resolveType) => {
        const request = new XMLHttpRequest();
        request.open("GET", URLbackend + `/${filepath}`, true);
        request.responseType = "blob";

        request.onload = async () => {
            const result = await readFileAsync(request.response);
            resolve(result);
        };

        request.send();
    });
}

function readFileAsync(file: File) {
    return new Promise((resolve: resolveType, reject) => {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
            if (reader) {
                resolve(reader.result);
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export function convertBase64ToBlob(fileUrl: string, fileName: string) {
    return new Promise((resolve) => {
        try {
            fetch(fileUrl)
                .then((res) => res.blob())
                .then((blob) => resolve(new File([blob], fileName)));
        } catch (e) {
            console.log(e);
        }
    });
}
