import moment from "moment";
import React from "react";
import {EnumCalendarDayTypes} from "src/common/enums/calendarDayTypes";
import {IconCalendarOK} from "src/shared/icons/IconCalendarOK";
import styled from "styled-components";

interface IProps {
    dayType?: EnumCalendarDayTypes;
    value: string;
    onClick?: (day: moment.Moment) => void;
    day?: moment.Moment;
    description?: string;
    visibleIcon?: boolean;
}

interface IState {
    hover: boolean;
    activeVisible: boolean;
}

export class CalendarCell extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {hover: false, activeVisible: false};
    }

    public render(): JSX.Element {
        return (
            <Container active={this.props.dayType !== 2} onMouseOver={this.onHoverOver} onMouseOut={this.onHoverOut}>
                <TopContainer>
                    {this.props.dayType === 4 ? <IconCalendarOK /> : ""}
                    {this.props.dayType !== 2 ? <Day>{this.props.value}</Day> : ""}
                </TopContainer>
                {this.props.dayType !== 2 && (
                    <Button visible={this.state.hover && this.props.dayType !== 2} onClick={this.onClick}>
                        {this.props.dayType === 4 ? "Подробнее" : "Добавить"}
                    </Button>
                )}
            </Container>
        );
    }

    private onHoverOver = () => {
        this.setState({hover: true});
    };
    private onHoverOut = () => {
        this.setState({hover: false});
    };

    private onClick = () => {
        const {day, onClick} = this.props;
        if (day != null && onClick != null) {
            onClick(day);
        }
    };
}

const Container = styled.div<{active: boolean}>`
    display: flex;
    flex-direction: column;
    background: white; /*${(props) => (props.active ? "white" : "#DDDDDD")};*/
    width: 95px;
    height: 95px;
    border: 1px solid #F7F7F7;
    padding: 12px;
`;
const Day = styled.div`
    text-align: right;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 600;
`;
const Button = styled.div<{visible: boolean}>`
    background: #1c6ffc;
    border-radius: 4px;
    box-sizing: border-box;
    align-self: center;
    padding: 5px 7px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    margin-top: 10px;

    :hover {
        background-color: #1963e2;
    }

    :active {
        background-color: #134db0;
    }
`;
const TopContainer = styled.div`
    display: flex;
`;
