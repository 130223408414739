import {ConnectedRouter} from "connected-react-router";
import moment from "moment";
import "moment/locale/ru";
import React from "react";
import {connect} from "react-redux";
import {Route, Switch, Redirect} from "react-router-dom";
import "./app.css";
import "./bootstrap.min.css";
import {LoginPageState} from "./components/loginPage/selector";
import {Tooltips} from "./components/screeenTooltips";
import {AddNewsPage} from "./components/screenAddNews";
import {CalendarFilePage} from "./components/screenCaledarFile";
import {CalendarPage} from "./components/screenCalendar";
import {CategoryPage} from "./components/screenCategory";
import {Header} from "./components/screenHeader";
import {LeftMenu} from "./components/screenMenu";
import {NewsPage} from "./components/screenNews";
import {ViewNewsPage} from "./components/screenViewNews";
import {configureStore} from "./core/store/configureStore";
import {styled, ThemeProvider} from "./core/styledComponents";
import {theme} from "./core/theme";
import {LoginPage} from "./modules/autorisation/Authorisation";

moment.locale("ru");
const {history} = configureStore();

const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};

interface IAppStateProps extends ReturnType<typeof LoginPageState> {}

export class Root extends React.Component<IAppStateProps> {
    public render() {
        return (
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <>
                        <Route component={ScrollToTop} />
                        <LeftMenu />
                        <Tooltips />
                        <Switch>
                            {this.props.token && this.props.token.token && this.props.token.token.length !== 0 ? (
                                <>
                                    <Container>
                                        <Header />
                                        <Route exact={true} path="/adminNews/create" component={AddNewsPage} />
                                        <Route exact={true} path="/adminNews/edit/:id" component={AddNewsPage} />
                                        <Route exact={true} path="/adminNews/view/:id" component={ViewNewsPage} />
                                        <Route exact={true} path="/adminCalendar" component={CalendarPage} />
                                        <Route exact={true} path="/adminAddFileCalendar" component={CalendarFilePage} />
                                        <Route exact={true} path="/adminCategory" component={CategoryPage} />
                                        <Route exact={true} path="/adminNews" component={NewsPage} />
                                        <Route exact={true} path={"/Login"} render={this.getRedirect("/adminNews")} />
                                    </Container>
                                </>
                            ) : (
                                <>
                                    <Redirect to={"/Login"} />
                                    <Route exact={true} path="/Login" component={LoginPage} />
                                </>
                            )}
                        </Switch>
                    </>
                </ThemeProvider>
            </ConnectedRouter>
        );
    }

    private getRedirect(path: string): () => JSX.Element {
        return (): JSX.Element => <Redirect to={path} />;
    }
}

export const App = connect(LoginPageState)(Root);

const Container = styled.div`
    display: flex;
    margin-left: 200px;
    background: #eaeaea;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
