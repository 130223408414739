import React from "react";
import styled from "styled-components";

export class Loader extends React.Component {
    public render() {
        return (
            <Container>
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M40 72C48.4869 72 56.6263 68.6286 62.6274 62.6274C68.6286 56.6263 72 48.4869 72 40C72 31.5131 68.6286 23.3737 62.6274 17.3726C56.6263 11.3714 48.4869 8 40 8C31.5131 8 23.3737 11.3714 17.3726 17.3726C11.3714 23.3737 8 31.5131 8 40C8 48.4869 11.3714 56.6263 17.3726 62.6274C23.3737 68.6286 31.5131 72 40 72ZM40 0C45.2529 0 50.4543 1.03463 55.3073 3.04482C60.1604 5.05501 64.5699 8.00139 68.2843 11.7157C71.9986 15.4301 74.945 19.8396 76.9552 24.6927C78.9654 29.5457 80 34.7471 80 40C80 50.6087 75.7857 60.7828 68.2843 68.2843C60.7828 75.7857 50.6087 80 40 80C17.88 80 0 62 0 40C0 29.3913 4.21427 19.2172 11.7157 11.7157C19.2172 4.21427 29.3913 0 40 0ZM42 20V41L60 51.68L57 56.6L36 44V20H42Z"
                        fill="#EAEAEA"
                    />
                </svg>
                <Title>Идёт поиск по запросу</Title>
            </Container>
        );
    }
}
const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    color: #959595;
    margin-top: 36px;
`;
