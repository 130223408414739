import React from "react";
import {connect} from "react-redux";
import {INews} from "src/core/api/dto/News.g";
import styled from "styled-components";
import {ISortParamDto} from "../../core/api/dto/SortParamDto.g";
import {IconTriangleFilter} from "../../shared/icons/IconTriangleFilter";
import {Loader} from "../../shared/icons/Loader";
import {BlueButton} from "../../ui/BlueButton";
import {ButtonGroup} from "../../ui/ButtonGroup";
import {OutsideClick} from "../../ui/OutsideClick";
import {RedButton} from "../../ui/RedButton";
import {SmallWindow, SmallWindowTitle} from "../../ui/SmallWindow";
import {TH} from "../../ui/TH";
import {TR} from "../../ui/TR";
import {ResultNotFound} from "../screenResultNotFound";
import {ItemNews} from "./itemNews";
import {AddNewsDispatch, NewsState} from "./selectors";

interface IProps extends ReturnType<typeof NewsState>, ReturnType<typeof AddNewsDispatch> {
    pageNumber: number;
}

interface IState {
    showDelete: boolean;
    deleteId: string;
    isDescFilter: "up" | "down" | "notActive";
    fieldName: string;
}

class TableNewsConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {showDelete: false, deleteId: "", isDescFilter: "notActive", fieldName: ""};
    }

    public render() {
        const data = this.props.data.items;
        return (
            <Container>
                <Table>
                    <tbody>
                        <TR>
                            <TH onClick={this.FilterTitle}>
                                <FilterBlock>
                                    Заголовок
                                    <IconTriangleFilter
                                        state={this.state.fieldName === "Title" ? this.state.isDescFilter : "notActive"}
                                    />
                                </FilterBlock>
                            </TH>
                            <TH onClick={this.FilterCategory}>
                                <FilterBlock>
                                    Категория
                                    <IconTriangleFilter
                                        state={
                                            this.state.fieldName === "CategoryId"
                                                ? this.state.isDescFilter
                                                : "notActive"
                                        }
                                    />
                                </FilterBlock>
                            </TH>
                            <TH onClick={this.FilterDate}>
                                <FilterBlock>
                                    Дата/Время
                                    <IconTriangleFilter
                                        state={
                                            this.state.fieldName === "CreatedUtc"
                                                ? this.state.isDescFilter
                                                : "notActive"
                                        }
                                    />
                                </FilterBlock>
                            </TH>
                            <TH onClick={this.FilterCity}>
                                <FilterBlock>
                                    Город
                                    <IconTriangleFilter
                                        state={
                                            this.state.fieldName === "CityId" ? this.state.isDescFilter : "notActive"
                                        }
                                    />
                                </FilterBlock>
                            </TH>
                            <TH>
                                <FilterBlock>Статус</FilterBlock>
                            </TH>
                            <TH>{""}</TH>
                        </TR>
                        {!this.props.loading &&
                            data &&
                            data.length !== 0 &&
                            data.map((item: INews, index: number) => (
                                <ItemNews
                                    key={index}
                                    // @ts-ignore
                                    data={item}
                                    page={this.props.pageNumber}
                                    SetDeleteId={this.SetDeleteId}
                                    ShowDeleteWindow={this.ShowDeleteWindow}
                                />
                            ))}
                    </tbody>
                </Table>
                {data && data.length === 0 && <ResultNotFound />}
                {this.props.loading && (
                    <ContainerLoader>
                        <Loader />
                    </ContainerLoader>
                )}

                <OutsideClick visible={this.state.showDelete} onClick={this.ShowDeleteWindow} />
                <SmallWindow active={this.state.showDelete}>
                    <SmallWindowTitle>Внимание</SmallWindowTitle>
                    <Text>Вы действительно хотите удалить новость?</Text>
                    <ButtonGroup center={false}>
                        <BlueButton onClick={this.DeletNews}>Удалить</BlueButton>
                        <RedButton onClick={this.ShowDeleteWindow}>Отмена</RedButton>
                    </ButtonGroup>
                </SmallWindow>
            </Container>
        );
    }

    public DeletNews = async () => {
        await this.props.deleteNews(this.state.deleteId);
        this.setState({showDelete: !this.state.showDelete});
        this.props.initialNews(this.props.pageNumber);
    };
    public ShowDeleteWindow = () => {
        this.setState({showDelete: !this.state.showDelete});
    };
    public SetDeleteId = (id: string) => {
        this.setState({deleteId: id});
    };

    private FilterTitle = async () => {
        if (this.state.fieldName === "Title") {
            await this.setState({isDescFilter: this.updateIsDescFilter(this.state.isDescFilter)});
        } else {
            await this.setState({fieldName: "Title", isDescFilter: "up"});
        }
        this.getRequest("Title");
    };
    private FilterCategory = async () => {
        if (this.state.fieldName === "CategoryId") {
            await this.setState({isDescFilter: this.updateIsDescFilter(this.state.isDescFilter)});
        } else {
            await this.setState({fieldName: "CategoryId", isDescFilter: "up"});
        }
        this.getRequest("CategoryId");
    };
    private FilterDate = async () => {
        if (this.state.fieldName === "CreatedUtc") {
            await this.setState({isDescFilter: this.updateIsDescFilter(this.state.isDescFilter)});
        } else {
            await this.setState({fieldName: "CreatedUtc", isDescFilter: "up"});
        }
        this.getRequest("CreatedUtc");
    };
    private FilterCity = async () => {
        if (this.state.fieldName === "CityId") {
            await this.setState({isDescFilter: this.updateIsDescFilter(this.state.isDescFilter)});
        } else {
            await this.setState({fieldName: "CityId", isDescFilter: "up"});
        }
        this.getRequest("CityId");
    };

    private updateIsDescFilter = (value: string): "up" | "down" | "notActive" => {
        if (value === "up") {
            return "down";
        }
        if (value === "down") {
            return "notActive";
        }
        if (this.state.isDescFilter === "notActive") {
            return "up";
        }
        return "notActive";
    };

    private getRequest = async (value: string) => {
        const {isDescFilter} = this.state;

        if (isDescFilter !== "notActive") {
            await this.props.updateFilterReduser({
                fieldName: value,
                isDesc: isDescFilter === "up"
            } as ISortParamDto);

            this.props.initialNews(this.props.pageNumber);
        } else {
            await this.props.updateFilterReduser({isDesc: true, fieldName: "CreatedUtc"} as ISortParamDto);

            this.props.initialNews(this.props.pageNumber);
        }
    };
}

export const TableNews = connect(
    NewsState,
    AddNewsDispatch
)(TableNewsConstructor);

export const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    tr:nth-child(2n) {
        background: #fff;
    }
    tr:nth-child(2n + 1) {
        background: #f7f7f7;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Text = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #384552;
    margin-bottom: 40px;
`;
const FilterBlock = styled.div`
    display: flex;
`;
const ContainerLoader = styled.div`
    height: 698px;
    background: #fff;
`;
