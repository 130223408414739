import arrayMutators from "final-form-arrays";
import React from "react";
import {Form, FormRenderProps} from "react-final-form";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import {INews, INewsForm} from "src/core/api/dto/News.g";
import {INewsShort} from "src/core/api/dto/NewsShort.g";
import styled from "styled-components";
import {BlueButton} from "../../ui/BlueButton";
import {RedButton} from "../../ui/RedButton";
import {InputFieldWrapper} from "./component/inputFieldWrapper";
import {ArrayFormAddNews, required, validatePreviewLine, validateSuccessful} from "./component/validation";
import {AddNewsDispatch, AddNewsState, convertBase64ToBlob, loadFileFromServer} from "./selectors";

interface IProps extends ReturnType<typeof AddNewsDispatch>, ReturnType<typeof AddNewsState>, RouteComponentProps {
    dataNews?: INews;
    id?: string;
}

interface IState {
    addNews: boolean;
}

class FormAddNewsConstructor extends React.Component<IProps, IState> {
    private initialValues: INewsForm;

    constructor(props: IProps) {
        super(props);
        this.state = {addNews: false};
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        this.initialValues =
            this.props.dataNews && this.props.dataNews.id
                ? {
                      ...this.props.dataNews,
                      cityId: this.props.dataNews.city ? this.props.dataNews.city.id : -1,
                      categoryId: this.props.dataNews.category.id,
                      filePhoto: this.props.dataNews.imagePath
                  }
                : ({} as INewsForm);
        validateSuccessful();
    }

    public render() {
        return (
            <Container>
                <Form
                    initialValues={this.initialValues}
                    onSubmit={this.onSubmit}
                    mutators={{...arrayMutators}}
                    render={this.renderForm}
                />
            </Container>
        );
    }

    private renderForm = (props: FormRenderProps): React.ReactNode => {
        const {
            handleSubmit,
            form: {}
            // ...rest
        } = props;

        return (
            <form onSubmit={handleSubmit}>
                <TopContainer>
                    <LeftBlock>
                        <InputFieldWrapper
                            arrayForm={ArrayFormAddNews}
                            name={"title"}
                            validate={required}
                            hasError={ArrayFormAddNews.title.showError}
                            maxLength={250}
                        />
                        <SelectBlock>
                            <InputFieldWrapper
                                arrayForm={ArrayFormAddNews}
                                name={"cityId"}
                                // validate={required}
                                hasError={false}
                                type={"select"}
                                options={this.props.dataCity}
                            />
                            <Padding />
                            <InputFieldWrapper
                                arrayForm={ArrayFormAddNews}
                                name={"categoryId"}
                                validate={required}
                                hasError={ArrayFormAddNews.categoryId.showError}
                                type={"select"}
                                options={this.props.dataCategory}
                            />
                        </SelectBlock>
                    </LeftBlock>
                    <RightBlock>
                        <ContainerDownloadFile>
                            <InputFieldWrapper
                                arrayForm={ArrayFormAddNews}
                                name={"filePhoto"}
                                hasError={false}
                                type="filePhoto"
                            />
                        </ContainerDownloadFile>
                    </RightBlock>
                </TopContainer>

                <CenterContsiner>
                    <InputFieldWrapper
                        arrayForm={ArrayFormAddNews}
                        name={"description"}
                        validate={required}
                        hasError={ArrayFormAddNews.description.showError}
                        type="wysiwyg"
                        maxLength={2000}
                    />
                </CenterContsiner>
                <ButtonContainer>
                    <BlueButton type="submit" onClick={this.ShowError}>
                        Сохранить
                    </BlueButton>
                    <RedButton onClick={this.goBack}>Отмена</RedButton>
                </ButtonContainer>
            </form>
        );
    };
    // tslint:disable-next-line
    private onSubmit = async (value: any) => {
        this.props.closeTooltips();
        const news: INewsShort = {
            cityId: value.cityId || null,
            categoryId: value.categoryId,
            title: value.title,
            description: value.description
        };

        if (this.props.dataNews && this.props.id) {
            if (value.filePhoto === this.props.dataNews.imagePath && this.props.dataNews.imagePath) {
                const photo = await loadFileFromServer(value.filePhoto);
                value.filePhoto = await convertBase64ToBlob(
                    photo ? photo.toString() : "",
                    value.filePhoto.replace("images/news/", "")
                );
            }
            await this.props.updateNews(this.props.id, value.filePuth, value.filePhoto, news);
        } else {
            await this.props.addNews(value.filePuth, value.filePhoto, news);
        }
        this.props.history.goBack();
    };

    private ShowError = () => {
        validatePreviewLine();
        this.props.showTooltips("Не все поля заполнены. Убедитесь, что все верно и повторите попытку.");
    };

    private goBack = () => {
        this.props.history.push("/adminNews");
    };
}

export const FormAddNews = compose(
    withRouter,
    connect(
        AddNewsState,
        AddNewsDispatch
    )
)(FormAddNewsConstructor);

const Container = styled.div`
    display: flex;
    width: 100%;
    color: white;
    flex-direction: column;
    align-self: center;
    padding: 23px 15px;
    border-radius: 4px;
`;
const ContainerDownloadFile = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
`;
const ButtonContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    padding: 23px 15px;
    border-radius: 4px;
`;
const LeftBlock = styled.div`
    flex: 1;
    margin-right: 15px;
`;
const RightBlock = styled.div`
    flex: 1;
    margin-left: 15px;
`;
const TopContainer = styled.div`
    display: flex;
    background: #ffffff;
    padding: 23px 15px;
    border-radius: 4px;
    margin-bottom: 10px;
`;
const CenterContsiner = styled.div`
    display: flex;
    background: #ffffff;
    padding: 23px 15px;
    border-radius: 4px;
    margin-bottom: 10px;
`;
const SelectBlock = styled.div`
    display: flex;
`;
const Padding = styled.div`
    width: 100px;
`;
