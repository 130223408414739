import {reducerWithInitialState} from "typescript-fsa-reducers";
import {INews} from "../../core/api/dto/News.g";
import {IPageResult} from "../../core/api/dto/PageResult.g";
import {NewsAction} from "./newsAction";
import {ISortParamDto} from "src/core/api/dto/SortParamDto.g";

export interface ICompanyItemState {
    loading: boolean;
    data: IPageResult<INews>;
    filterSort?: ISortParamDto;
    titleSort?: string;
    categorySort?: string;
}

const ItemInitialState: ICompanyItemState = {
    data: {} as IPageResult<INews>,
    loading: true,
    categorySort: "",
    titleSort: undefined,
    filterSort: {} as ISortParamDto
};
export const NewsReducer = reducerWithInitialState(ItemInitialState)
    .case(NewsAction.getByFilter.started, (state) => ({
        data: state.data,
        loading: true,
        titleSort: state.titleSort,
        categorySort: state.categorySort,
        filterSort: state.filterSort
    }))
    .case(NewsAction.getByFilter.done, (state, payload) => ({
        data: payload.result,
        loading: false,
        titleSort: state.titleSort,
        categorySort: state.categorySort,
        filterSort: state.filterSort
    }))
    .case(NewsAction.getByFilter.failed, (state) => ({
        data: state.data,
        loading: false,
        titleSort: state.titleSort,
        categorySort: state.categorySort,
        filterSort: state.filterSort
    }))

    .case(NewsAction.deleteUpdateAdd.started, (state) => ({
        data: state.data,
        loading: true,
        titleSort: state.titleSort,
        categorySort: state.categorySort,
        filterSort: state.filterSort
    }))
    .case(NewsAction.deleteUpdateAdd.done, (state) => ({
        data: state.data,
        loading: false,
        titleSort: state.titleSort,
        categorySort: state.categorySort,
        filterSort: state.filterSort
    }))
    .case(NewsAction.deleteUpdateAdd.failed, (state) => ({
        data: state.data,
        loading: false,
        titleSort: state.titleSort,
        categorySort: state.categorySort,
        filterSort: state.filterSort
    }))

    .case(NewsAction.updateSortCategoryParam, (state, payload) => ({
        data: state.data,
        loading: false,
        categorySort: payload,
        titleSort: state.titleSort,
        filterSort: state.filterSort
    }))

    .case(NewsAction.updateSortFilterParam, (state, payload) => ({
        data: state.data,
        loading: false,
        filterSort: payload,
        titleSort: undefined,
        categorySort: state.categorySort
    }))

    .case(NewsAction.updateSortTitleParam, (state, payload) => ({
        data: state.data,
        loading: false,
        filterSort: undefined,
        titleSort: payload,
        categorySort: state.categorySort
    }));
