import React from "react";
import styled from "styled-components";
import {IconLupa} from "../shared/icons/IconLupa";

export class ResultNotFound extends React.Component {
    public render() {
        return (
            <ContainerNotFound>
                <IconLupa />
                <Text>Нет результатов...</Text>
            </ContainerNotFound>
        );
    }
}

const ContainerNotFound = styled.div`
    display: flex;
    width: 100%;
    height: 698px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
`;
const Text = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    color: #959595;
    margin-top: 36px;
`;
