import {ICategoryShort} from "../../core/api/dto/CategoryShort.g";
import {requestsRepository} from "../../core/api/RequestRepository";
import {IThunkAction} from "../../core/store/mainReducer";
import {TooltipsAction} from "../tooltips/tooltipsAction";
import {CategoryAction} from "./categoryAction";

export class CategoryActionAsync {
    public static LoadingCategory(): IThunkAction {
        return async (dispatch) => {
            dispatch(CategoryAction.getRoot.started({params: {}}));
            try {
                const result = await requestsRepository.categoryApiRequest.getRoot();
                dispatch(CategoryAction.getRoot.done({params: {}, result}));
            } catch (error) {
                dispatch(CategoryAction.getRoot.failed({params: {}, error}));
            }
        };
    }

    public static Create(name: string): IThunkAction {
        return async (dispatch) => {
            dispatch(CategoryAction.add.started({params: {}}));
            const request: ICategoryShort = {
                name,
                parentId: null
            };
            try {
                await requestsRepository.categoryApiRequest.add(request);
                dispatch(this.LoadingCategory());

                dispatch(CategoryAction.add.done({params: {}, result: {}}));

                dispatch(TooltipsAction.showTooltips("Категория успешно добавлена"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается создать категорию. Повторите попытку позже."
                    )
                );

                dispatch(CategoryAction.add.failed({params: {}, error}));
            }
        };
    }

    public static Update(id: string, name: string): IThunkAction {
        return async (dispatch) => {
            dispatch(CategoryAction.update.started({params: {}}));
            const request: ICategoryShort = {
                name,
                parentId: null
            };
            try {
                await requestsRepository.categoryApiRequest.update(id, request);
                dispatch(this.LoadingCategory());

                dispatch(CategoryAction.update.done({params: {}, result: {}}));

                dispatch(TooltipsAction.showTooltips("Категория успешно обновлена"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается обновить категорию. Повторите попытку позже."
                    )
                );

                dispatch(CategoryAction.update.failed({params: {}, error}));
            }
        };
    }

    public static Delete(id: string): IThunkAction {
        return async (dispatch) => {
            dispatch(CategoryAction.delete.started({params: {}}));
            try {
                await requestsRepository.categoryApiRequest.delete(id);
                dispatch(this.LoadingCategory());

                dispatch(CategoryAction.delete.done({params: {}, result: {}}));

                dispatch(TooltipsAction.showTooltips("Категория успешно удалена"));
            } catch (error) {
                if (error === "News depends on this category") {
                    dispatch(TooltipsAction.showTooltips("Невозможно удалить категорию на которую создана новость"));
                } else {
                    dispatch(TooltipsAction.showTooltips("Неизвестная ошибка"));
                }
                dispatch(CategoryAction.delete.failed({params: {}, error}));
            }
        };
    }
}
