import React, {ChangeEvent} from "react";
import styled from "styled-components";
import {InputFile} from "../newsPage/component/downloadFile";

interface IProps {
    year: number;
    downloadFileFiveDay: (file: File, year: number) => void;
    downloadFileSixDay: (file: File, year: number) => void;
    deleteFile: (id: number | undefined) => void;
    fileNameFiveDay: string;
    fileNameSixDay: string;
    idFileFiveDay?: number;
    idFileSixDay?: number;
}

export class BlockAddFile extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        console.log(this.props.idFileSixDay);
        return (
            <>
                <Type>Для шестидневной недели на {Number(this.props.year)}</Type>
                <LoadDayContainer>
                    <ButtonAddFile>
                        {" "}
                        Выбрать файл
                        <InputFile type={"file"} onChange={this.SixDayFileThisYear} />
                    </ButtonAddFile>
                    {this.props.fileNameSixDay !== "Файл не загружен" && (
                        // tslint:disable-next-line
                        <ButtonDeleteFile onClick={() => this.props.deleteFile(this.props.idFileSixDay)}>
                            Удалить
                        </ButtonDeleteFile>
                    )}
                    <FileName>{this.props.fileNameSixDay}</FileName>
                </LoadDayContainer>
                <Type>Для пятидневной недели на {Number(this.props.year)}</Type>
                <LoadDayContainer>
                    <ButtonAddFile>
                        {" "}
                        Выбрать файл
                        <InputFile type={"file"} onChange={this.FiveDayFileThisYear} />
                    </ButtonAddFile>
                    {this.props.fileNameFiveDay !== "Файл не загружен" && (
                        // tslint:disable-next-line
                        <ButtonDeleteFile onClick={() => this.props.deleteFile(this.props.idFileFiveDay)}>
                            Удалить
                        </ButtonDeleteFile>
                    )}
                    <FileName>{this.props.fileNameFiveDay}</FileName>
                </LoadDayContainer>
            </>
        );
    }

    private FiveDayFileThisYear = (event: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = event.target.files[0];
        this.props.downloadFileFiveDay(file, this.props.year);
    };
    private SixDayFileThisYear = (event: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = event.target.files[0];
        this.props.downloadFileSixDay(file, this.props.year);
    };
}

const LoadDayContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Type = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #384552;
    margin: 20px 0 10px 0;
`;
const ButtonAddFile = styled.label`
    background: #1c6ffc;
    border-radius: 4px;
    padding: 7px 16px;
    margin-right: 30px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    :hover {
        background-color: #1963e2;
    }

    :active {
        background-color: #134db0;
    }
`;
const FileName = styled.a`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    word-break: break-word;
    color: #384552;
    :hover,
    :active,
    :focus {
        color: #384552;
        cursor: pointer;
    }
`;

const ButtonDeleteFile = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    color: rgb(255, 255, 255);
    background: rgb(239, 89, 125);
    border-radius: 4px;
    padding: 6px 40px;
    margin-right: 30px;
    cursor: pointer;
`;
