import {createSelector} from "reselect";
import {CalendarType} from "../../core/api/dto/CalendarType.g";
import {IAppDispatch, IAppState} from "../../core/store/mainReducer";
import {CalendarFilesActionAsync} from "../../modules/calendarFiles/calendarFilesActionAsync";

export const CalendarAddFileState = createSelector(
    (state: IAppState) => state.calendarFilesReduser.loading,
    (state: IAppState) => state.calendarFilesReduser.data,
    (loading, data) => ({loading, data})
);

export const CalendarAddFileDispatch = (dispatch: IAppDispatch) => ({
    loadFile: (file: File, year: number, type: CalendarType) =>
        dispatch(CalendarFilesActionAsync.LoadFile(file, year, type)),
    initialFiles: () => dispatch(CalendarFilesActionAsync.InitialFiles()),
    deleteFiles: (id: number) => dispatch(CalendarFilesActionAsync.DeleteFile(id))
});
