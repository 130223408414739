import styled from "styled-components";

export const BlueButton = styled.button`
    background: #1c6ffc;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 6px 40px;
    margin-right: 30px;
    position: relative;
    transition: all 0.1s ease-in-out;

    :hover {
        background-color: #1963e2;
    }

    :active {
        background-color: #134db0;
    }
`;
