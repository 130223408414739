import React from "react";
import {IconDelete} from "src/shared/icons/IconDelete";
import {IconPen} from "src/shared/icons/IconPen";
import styled from "styled-components";
import {IconView} from "../../shared/icons/IconView";

interface IProps {
    id: string;
    page: number;
    SetDeleteId: (value: string) => void;
    ShowDeleteWindow: () => void;
    UpdateNews: () => void;
    ViewNews: () => void;
}

export class ItemMenu extends React.Component<IProps> {
    public render() {
        return (
            <Container>
                <Redactor onClick={this.clickView}>
                    <IconView />
                </Redactor>
                <Redactor onClick={this.onUpdate}>
                    <IconPen />
                </Redactor>
                <Delete onClick={this.clickDelete}>
                    <IconDelete />
                </Delete>
            </Container>
        );
    }

    public onUpdate = () => {
        this.props.UpdateNews();
    };
    public clickDelete = () => {
        this.props.SetDeleteId(this.props.id);
        this.props.ShowDeleteWindow();
    };
    public clickView = () => {
        this.props.ViewNews();
    };
}

const Container = styled.div`
    background: inherit;
    width: 125px;
    display: flex;
    align-items: center;
`;
export const Redactor = styled.div`
    cursor: pointer;
    margin-right: 20px;
`;
export const Delete = styled.div`
    padding: 5px;
    cursor: pointer;
`;
