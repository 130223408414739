import React from "react";
import {connect} from "react-redux";
import {IconPen} from "src/shared/icons/IconPen";
import {IconDelete} from "src/shared/icons/IconDelete";
import styled from "styled-components";
import {Loader} from "../shared/icons/Loader";
import {BlueButton} from "../ui/BlueButton";
import {ButtonGroup} from "../ui/ButtonGroup";
import {Container} from "../ui/Container";
import {OutsideClick} from "../ui/OutsideClick";
import {RedButton} from "../ui/RedButton";
import {SmallWindow, SmallWindowTitle} from "../ui/SmallWindow";
import {TD} from "../ui/TD";
import {TH} from "../ui/TH";
import {TR} from "../ui/TR";
import {CategoryDispatch, CategoryState} from "./category/selectors";
import {WindowAddCategory} from "./category/windowAddCategory";
import {WindowUpdateCategory} from "./category/windowUpdateCategory";
import {Delete, Redactor} from "./newsPage/itemMenu";
import {Table, Text} from "./newsPage/tableInfo";

interface IProps extends ReturnType<typeof CategoryDispatch>, ReturnType<typeof CategoryState> {}

interface IState {
    visibleFormAdd: boolean;
    pageNumber: number;
    updateNews: string;
    formAddCategory: boolean;
    deleteId: string | undefined;
    nameNewCategory: string;
    visibleFormUpdate: boolean;
}

class CategoryPageConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            visibleFormAdd: false,
            pageNumber: 1,
            updateNews: "",
            formAddCategory: false,
            deleteId: "",
            nameNewCategory: "",
            visibleFormUpdate: false
        };
    }

    public componentDidMount() {
        this.props.initialCategory();
    }

    public render() {
        const {data} = this.props;
        return (
            <CategoryContainer>
                <BottomContainer>
                    <ButtonAdd onClick={this.showFormAddCategory}>Создать категорию</ButtonAdd>
                </BottomContainer>

                {this.props.loading ? (
                    <ContainerLoader>
                        <Loader />
                    </ContainerLoader>
                ) : (
                    <>
                        <Table>
                            <tbody>
                                <TR>
                                    <TH>Название категории</TH>
                                    <TH>{""}</TH>
                                </TR>
                                {data && data.length !== 0 ? (
                                    data.map((item, index) => (
                                        <TR key={index}>
                                            <TD>
                                                {item.name.length > 100 ? item.name.substr(0, 100) + "..." : item.name}
                                            </TD>
                                            <TD style={{textAlign: "center"}}>
                                                <FunctionBlock>
                                                    {/* tslint:disable-next-line */}
                                                    <Redactor onClick={() => this.showFormAUpdateCategory(item.id)}>
                                                        <IconPen />
                                                    </Redactor>
                                                    {/* tslint:disable-next-line */}
                                                    <Delete onClick={() => this.ShowDeleteWindow(item.id)}>
                                                        <IconDelete />
                                                    </Delete>
                                                </FunctionBlock>
                                            </TD>
                                        </TR>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </tbody>
                        </Table>
                        {/* tslint:disable-next-line */}
                        <OutsideClick visible={!!this.state.deleteId} onClick={() => this.ShowDeleteWindow()} />
                        <SmallWindow active={!!this.state.deleteId}>
                            <SmallWindowTitle>Внимание</SmallWindowTitle>
                            <Text>Вы действительно хотите удалить категорию?</Text>
                            <ButtonGroup center={false}>
                                <BlueButton onClick={this.clickDelete}>Удалить</BlueButton>
                                {/* tslint:disable-next-line */}
                                <RedButton onClick={() => this.ShowDeleteWindow()}>Отмена</RedButton>
                            </ButtonGroup>
                        </SmallWindow>
                    </>
                )}
                <WindowAddCategory
                    visibleFormAdd={this.state.visibleFormAdd}
                    showFormAddCategory={this.showFormAddCategory}
                />
                <WindowUpdateCategory
                    visibleFormAdd={this.state.visibleFormUpdate}
                    showFormUpdateCategory={this.showFormAUpdateCategory}
                    id={this.state.deleteId || ""}
                />
            </CategoryContainer>
        );
    }

    private showFormAddCategory = () => {
        this.setState({visibleFormAdd: !this.state.visibleFormAdd});
    };
    private clickDelete = () => {
        if (this.state.deleteId) {
            this.props.delete(this.state.deleteId);
            this.setState({deleteId: undefined});
        }
    };
    private ShowDeleteWindow = (id?: string) => {
        this.setState({deleteId: id});
    };
    private showFormAUpdateCategory = (id?: string) => {
        this.setState({deleteId: id, visibleFormUpdate: !this.state.visibleFormUpdate});
    };
}

export const CategoryPage = connect(
    CategoryState,
    CategoryDispatch
)(CategoryPageConstructor);

const CategoryContainer = styled(Container)`
    align-items: center;
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px 15px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
`;
const ButtonAdd = styled(BlueButton)`
    max-width: 200px;
    padding: 7px 15px;
`;

const ContainerLoader = styled.div`
    height: 698px;
    background: #fff;
    width: 100%;
`;
const FunctionBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
