import {RouterState} from "connected-react-router";
import {Action, combineReducers} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {LoginReducer} from "../../modules/autorisation/loginReducer";
import {CalendarReducer} from "../../modules/calendar/calendarReducer";
import {CalendarFilesReducer} from "../../modules/calendarFiles/calendarFilesReduser";
import {CategoryReducer} from "../../modules/category/categoryReducer";
import {CityReducer} from "../../modules/city/cityReducer";
import {NewsReducer} from "../../modules/news/newsReducer";
import {TooltipsReducer} from "../../modules/tooltips/tooltipsReduser";

export const mainReducer = combineReducers({
    newsReduser: NewsReducer,
    loginReduser: LoginReducer,
    categoryReduser: CategoryReducer,
    cityReduser: CityReducer,
    tooltipsReduser: TooltipsReducer,
    calendarReduser: CalendarReducer,
    calendarFilesReduser: CalendarFilesReducer
});

export interface IAppState extends ReturnType<typeof mainReducer> {
    router: RouterState;
}

export interface IAppDispatch extends ThunkDispatch<IAppState, Error, Action> {}

// export type SimpleThunk = (dispatch: IAppDispatch, getState: () => IAppState) => Promise<void>;
export interface IThunkAction extends ThunkAction<Promise<void>, IAppState, Error, Action> {}
