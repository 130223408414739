import * as moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Loader} from "src/shared/icons/Loader";
import styled from "styled-components";
import {CalendarType} from "../core/api/dto/CalendarType.g";
import {Container} from "../ui/Container";
import {GorisontLine} from "../ui/GorisontLine";
import {BlockAddFile} from "./calendarAddFile/blockAddFile";
import {CalendarAddFileDispatch, CalendarAddFileState} from "./calendarAddFile/selectors";

interface IProps extends ReturnType<typeof CalendarAddFileDispatch>, ReturnType<typeof CalendarAddFileState> {}

interface IState {
    year: number;
    standartFileName: string;
}

class CalendarFilePageConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            year: Number(moment().format("YYYY")),
            standartFileName: "Файл не загружен"
        };
    }

    public componentDidMount(): void {
        this.props.initialFiles();
    }

    public render() {
        const {data} = this.props;
        console.log(data);
        return (
            <AddFileContainer>
                {this.props.loading ? (
                    <Loader />
                ) : (
                    <>
                        <Title>Загрузка файла календаря на сайт для скачивания</Title>
                        {data &&
                            data.map((item) => (
                                <>
                                    <BlockAddFile
                                        downloadFileFiveDay={this.FiveDayFile}
                                        downloadFileSixDay={this.SixDayFile}
                                        deleteFile={this.DeleteFile}
                                        idFileFiveDay={item.file.fiveDay ? item.file.fiveDay.id : undefined}
                                        idFileSixDay={item.file.sixDay ? item.file.sixDay.id : undefined}
                                        fileNameFiveDay={
                                            item.file.fiveDay
                                                ? decodeURI(
                                                      item.file.fiveDay.fileName.substr(
                                                          item.file.fiveDay.fileName.indexOf("fivedays_") + 9
                                                      )
                                                  )
                                                : this.state.standartFileName
                                        }
                                        fileNameSixDay={
                                            item.file.sixDay
                                                ? decodeURI(
                                                      item.file.sixDay.fileName.substr(
                                                          item.file.sixDay.fileName.indexOf("sixdays_") + 8
                                                      )
                                                  )
                                                : this.state.standartFileName
                                        }
                                        year={item.year}
                                    />
                                    <GorisontLine />
                                    {console.log(item)}
                                </>
                            ))}
                    </>
                )}
            </AddFileContainer>
        );
    }

    private FiveDayFile = async (file: File, year: number) => {
        await this.props.loadFile(file, year, CalendarType.FiveDays);
        this.props.initialFiles();
    };
    private SixDayFile = async (file: File, year: number) => {
        await this.props.loadFile(file, year, CalendarType.SixDays);
        this.props.initialFiles();
    };

    private DeleteFile = (id: number) => {
        this.props.deleteFiles(id);
        this.props.initialFiles();
    };
}

export const CalendarFilePage = connect(
    CalendarAddFileState,
    CalendarAddFileDispatch
)(CalendarFilePageConstructor);

const Title = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    color: #384552;
`;
const AddFileContainer = styled(Container)`
    background: #fff;
    min-height: 730px;
    padding: 20px 15px;
    justify-content: flex-start;
`;
