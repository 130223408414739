import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import {applyMiddleware, compose, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import {mainReducer} from "./mainReducer";

const composeEnhancers =
    process.env.NODE_ENV === "production"
        ? compose
        : composeWithDevTools({
              shouldHotReload: false
          });

export function configureStore() {
    const history = createBrowserHistory();
    const middleware =
        process.env.NODE_ENV === "development"
            ? [thunk, routerMiddleware(history), createLogger()]
            : [thunk, routerMiddleware(history)];
    const enhancer = composeEnhancers(applyMiddleware(...middleware));
    const store = createStore(connectRouter(history)(mainReducer), enhancer);

    return {store, history};
}
