import {ICalendarFiles} from "../../core/api/dto/CalendarFiles.g";
import {CalendarType} from "../../core/api/dto/CalendarType.g";
import {actionCreator} from "../../core/store/common/actionCreator";

export class CalendarFilesActions {
    public static addFile = actionCreator.async<ICalendarAddFileParams, IEmpty, Error>("Calendar/ADD_FILE");
    public static initialFiles = actionCreator.async<IEmpty, ICalendarFilesInitialParams[], Error>(
        "Calendar/INITIAL_FILE"
    );
}

export interface ICalendarAddFileParams {
    file: File;
    year: number;
    type: CalendarType;
}

export interface ICalendarFilesInitialParams {
    year: number;
    file: {
        fiveDay: ICalendarFiles | undefined;
        sixDay: ICalendarFiles | undefined;
    };
}
