import * as moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import {INews} from "src/core/api/dto/News.g";
import styled, {css} from "styled-components";
import {BlueButton} from "../../ui/BlueButton";
import {TD} from "../../ui/TD";
import {WhiteButton} from "../../ui/WhiteButton";
import {ItemMenu} from "./itemMenu";
import {NewsDispatch} from "./selectors";

interface IProps extends RouteComponentProps, ReturnType<typeof NewsDispatch> {
    data: INews;
    page: number;
    SetDeleteId: (value: string) => void;
    ShowDeleteWindow: () => void;
}

interface IState {
    active: boolean;
    visibleFloatingmes: boolean;
    positionMouseX: number;
    positionMouseY: number;
    lengthTitle: number;
}

class ItemNewsConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {active: false, visibleFloatingmes: false, positionMouseX: 0, positionMouseY: 0, lengthTitle: 0};
    }

    public componentDidMount(): void {
        if (window.innerWidth >= 1700) {
            this.setState({lengthTitle: 50});
        }
        if (window.innerWidth >= 1600 && window.innerWidth < 1700) {
            this.setState({lengthTitle: 40});
        }
        if (window.innerWidth >= 1500 && window.innerWidth < 1600) {
            this.setState({lengthTitle: 30});
        }
        if (window.innerWidth >= 1400 && window.innerWidth < 1500) {
            this.setState({lengthTitle: 20});
        }
        if (window.innerWidth < 1400) {
            this.setState({lengthTitle: 10});
        }
    }

    public render() {
        const {data} = this.props;
        return (
            <TR>
                <TD onMouseMove={this.onHoverMouse} onMouseLeave={this.onOutMouse}>
                    {data.title.length > this.state.lengthTitle
                        ? data.title.substr(0, this.state.lengthTitle) + "..."
                        : data.title}
                </TD>
                <TD>{data.category.name}</TD>
                <TD>{moment(data.createdUtc).format("DD MMMM YYYY, HH:mm:ss")}</TD>
                <TD>{data.city ? data.city.title : ""}</TD>
                <TD>
                    {data.isPublish ? (
                        <WhiteButton style={{fontSize: "12px"}} onClick={this.unPublicate}>
                            Снять с публикации
                        </WhiteButton>
                    ) : (
                        <BlueButton style={{fontSize: "12px"}} onClick={this.publicate}>
                            Опубликовать
                        </BlueButton>
                    )}
                </TD>
                <TD style={{textAlign: "center"}}>
                    <ItemMenu
                        id={data.id}
                        page={this.props.page}
                        SetDeleteId={this.props.SetDeleteId}
                        ShowDeleteWindow={this.props.ShowDeleteWindow}
                        UpdateNews={this.UpdateNews}
                        ViewNews={this.ViewNews}
                    />
                </TD>
                <Floatingmes
                    style={{left: `${this.state.positionMouseX + 10}px`, top: `${this.state.positionMouseY + 10}px`}}
                    visible={this.state.visibleFloatingmes}>
                    {data.title}
                </Floatingmes>
            </TR>
        );
    }

    private UpdateNews = () => {
        this.props.history.push(`/adminNews/edit/${this.props.data.id}`);
    };
    private ViewNews = () => {
        this.props.history.push(`/adminNews/view/${this.props.data.id}`);
    };
    private onHoverMouse = (event: React.MouseEvent<HTMLTableDataCellElement>) => {
        this.setState({visibleFloatingmes: true, positionMouseX: event.clientX, positionMouseY: event.clientY});
    };
    private onOutMouse = () => {
        this.setState({visibleFloatingmes: false});
    };
    private publicate = () => {
        this.props.publicate(this.props.data.id, this.props.page);
    };
    private unPublicate = () => {
        this.props.unPublicate(this.props.data.id, this.props.page);
    };
}

export const ItemNews = compose(
    withRouter,
    connect(
        undefined,
        NewsDispatch
    )
)(ItemNewsConstructor);

const TR = styled.tr`
    width: 100%;
`;

const Floatingmes = styled.td<{visible: boolean}>`
    display: ${(props) => (props.visible ? "block" : "none")};
    position: absolute;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    background: white;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px;
    max-width: 400px;
    z-index: 999;
    ${({visible}) =>
        visible &&
        css`
            word-break: break-word;
            left: 0;
        `};
`;
