import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {compose} from "redux";
import styled from "styled-components";
import {BlueButton} from "../ui/BlueButton";
import {Container} from "../ui/Container";
import {Pagination} from "./newsPage/component/Pagination";
import {NewsDispatch, NewsState} from "./newsPage/selectors";
import {SortComponent} from "./newsPage/sortComponent";
import {TableNews} from "./newsPage/tableInfo";

interface IProps extends ReturnType<typeof NewsDispatch>, ReturnType<typeof NewsState>, RouteComponentProps {}

interface IState {
    visibleFormAdd: boolean;
    pageNumber: number;
    formAddCategory: boolean;
}

class NewsPageConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {visibleFormAdd: false, pageNumber: 1, formAddCategory: false};
    }

    public componentDidMount() {
        this.props.updateCategoryReduser("");
        this.props.updateTitleReduser("");
        this.props.initialNews(this.state.pageNumber);
        this.props.initialCategory();
        this.props.initialCity();
    }

    public render() {
        return (
            <Container>
                <TopContainer>
                    <SortComponent changePage={this.changePage} />
                    <ButtonAdd onClick={this.goFormAddNews}>Добавить новость</ButtonAdd>
                </TopContainer>
                <TableNews pageNumber={this.state.pageNumber} />
                <BottomContainer>
                    {!this.props.loading ? (
                        <Pagination
                            activePage={this.state.pageNumber}
                            countPage={Math.ceil(this.props.data.count / 8)}
                            onClick={this.changePage}
                        />
                    ) : (
                        <></>
                    )}
                </BottomContainer>
            </Container>
        );
    }

    private goFormAddNews = () => {
        this.props.history.push("/adminNews/create");
    };
    private changePage = (value: number) => {
        if (value < 1) {
            this.setState({pageNumber: 1});
        } else if (value > Math.ceil(this.props.data.count / 8)) {
            this.setState({pageNumber: Math.ceil(this.props.data.count / 8)});
        } else {
            this.setState({pageNumber: value});
        }
        this.props.initialNews(value);
    };
}

export const NewsPage = compose(
    withRouter,
    connect(
        NewsState,
        NewsDispatch
    )
)(NewsPageConstructor);

const TopContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px 15px;
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 12px 15px;
    justify-content: flex-end;
`;
const ButtonAdd = styled(BlueButton)`
    max-width: 200px;
    padding: 10px 15px;
`;
