import styled from "styled-components";

export const RedButton = styled.button`
    background: #ef597d;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 6px 40px;

    :hover {
        background-color: #d75070;
    }

    :active {
        background-color: #a73e57;
    }
`;
