import React, {SyntheticEvent} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {BlueButton} from "../../ui/BlueButton";
import {Select} from "../../ui/Select";
import {AddNewsState, NewsDispatch} from "./selectors";

interface IProps extends ReturnType<typeof AddNewsState>, ReturnType<typeof NewsDispatch> {
    changePage: (value: number) => void;
}

interface IState {
    valueCategory: string;
    valueTitle: string;
}

class SortComponentConstructor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {valueCategory: "", valueTitle: ""};
    }

    public render() {
        return (
            <Container>
                <ContainerSelect>
                    <Text>Заголовок:</Text>
                    <Input onChange={this.onInput} />
                </ContainerSelect>
                <ContainerSelect>
                    <Text>Категория:</Text>
                    <Select
                        value={this.state.valueCategory}
                        onChange={this.onSelectCategory}
                        options={this.props.dataCategory}
                    />
                </ContainerSelect>
                <Search onClick={this.onSearch}>Поиск</Search>
            </Container>
        );
    }

    private onSelectCategory = (value: string) => {
        this.setState({valueCategory: value});
        this.props.updateCategoryReduser(value);
    };
    private onInput = (value: SyntheticEvent<HTMLInputElement>) => {
        this.setState({valueTitle: value.currentTarget.value});
        this.props.updateTitleReduser(value.currentTarget.value);
    };
    private onSearch = () => {
        this.props.changePage(1);
    };
}

export const SortComponent = connect(
    AddNewsState,
    NewsDispatch
)(SortComponentConstructor);

const Container = styled.div`
    display: flex;
    align-items: center;
`;
const Text = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: #9ea7b0;
`;
const ContainerSelect = styled.div`
    width: 180px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
`;
const Input = styled.input`
    color: #000000;
    border: 1px solid #9ea7b0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 15px;
`;
const Search = styled(BlueButton)`
    padding: 10px 50px;
    height: 100%;
    margin-top: 11px;
`;
