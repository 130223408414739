import {ICalendarDateShort} from "../../core/api/dto/CalendarDateShort.g";
import {requestsRepository} from "../../core/api/RequestRepository";
import {IThunkAction} from "../../core/store/mainReducer";
import {TooltipsAction} from "../tooltips/tooltipsAction";
import {CalendarActions} from "./calendarActions";

export class CalendarCelebrateData {
    public static LoadItem(month: number, year: number): IThunkAction {
        return async (dispatch) => {
            await dispatch(CalendarActions.getByMonth.started({params: {}}));
            try {
                const result = await requestsRepository.calendarApiRequest.getByMonth(month, year);
                await dispatch(CalendarActions.getByMonth.done({params: {}, result}));
            } catch (error) {
                await dispatch(CalendarActions.getByMonth.failed({params: {}, error}));
            }
        };
    }

    public static CreateCelebrate(date: ICalendarDateShort): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.create(date);
                dispatch(TooltipsAction.showTooltips("Праздник успешно добавлен"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается создать праздник. Повторите попытку позже."
                    )
                );
            }
        };
    }

    public static UpdateCelebrate(id: string, date: ICalendarDateShort): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.update(id, date);
                dispatch(TooltipsAction.showTooltips("Праздник успешно обновлён"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается обновить праздник. Повторите попытку позже."
                    )
                );
            }
        };
    }

    public static DeleteCelebrate(id: string): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.delete(id);
                dispatch(TooltipsAction.showTooltips("Праздник успешно удалён"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается удалить праздник. Повторите попытку позже."
                    )
                );
            }
        };
    }

    public static FillYearWeekend(year: number): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.fillYearWeekend(year);
                dispatch(TooltipsAction.showTooltips("Выходные успешно добавлены"));
            } catch (error) {
                dispatch(TooltipsAction.showTooltips("Что-то пошло не так. Повторите попытку позже."));
            }
        };
    }

    public static TransferHoliday(fromYear: number, toYear: number): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.calendarApiRequest.fillYear(fromYear, toYear);
                dispatch(TooltipsAction.showTooltips("Празники успешно перенесены"));
            } catch (error) {
                dispatch(TooltipsAction.showTooltips("Что-то пошло не так. Повторите попытку позже."));
            }
        };
    }
}
