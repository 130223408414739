import {countNews} from "src/common/constants";
import {INewsShort} from "src/core/api/dto/NewsShort.g";
import {IDateFilter} from "../../core/api/dto/DateFilter.g";
import {INewsFilter} from "../../core/api/dto/NewsFilter.g";
import {ISortParamDto} from "../../core/api/dto/SortParamDto.g";
import {requestsRepository} from "../../core/api/RequestRepository";
import {IThunkAction} from "../../core/store/mainReducer";
import {TooltipsAction} from "../tooltips/tooltipsAction";
import {NewsAction} from "./newsAction";

export class NewsDataActionAsync {
    public static AddParamCategorySortByReduser(value: string): IThunkAction {
        return async (dispatch) => {
            dispatch(NewsAction.updateSortCategoryParam(value));
        };
    }

    public static AddParamTitleSortByReducer(value: string): IThunkAction {
        return async (dispatch) => {
            await dispatch(NewsAction.updateSortTitleParam(value));
        };
    }

    public static AddParamFilterSortByReduser(value: ISortParamDto): IThunkAction {
        return async (dispatch) => {
            await dispatch(NewsAction.updateSortFilterParam(value));
        };
    }

    public static SortNews(pageNumber: number): IThunkAction {
        return async (dispatch, getState) => {
            const state = getState().newsReduser;

            const filter: INewsFilter = {
                cityId: undefined,
                categoryId: state.categorySort,
                title: state.titleSort || "",
                dateFilter: {} as IDateFilter,
                sortParams: state.filterSort || ({isDesc: true, fieldName: "CreatedUtc"} as ISortParamDto)
            };

            dispatch(NewsAction.getByFilter.started({params: {}}));
            try {
                const result = await requestsRepository.newsApiRequest.getByFilter(pageNumber, countNews, filter);
                dispatch(NewsAction.getByFilter.done({params: "", result}));
            } catch (error) {
                dispatch(NewsAction.getByFilter.failed({params: "", error}));
            }
        };
    }

    public static DeleteNews(id: string): IThunkAction {
        return async (dispatch) => {
            dispatch(NewsAction.deleteUpdateAdd.started({params: {}}));
            try {
                await requestsRepository.newsApiRequest.delete(id);
                dispatch(NewsAction.deleteUpdateAdd.done({params: {}, result: {}}));

                dispatch(TooltipsAction.showTooltips("Новость успешно удалена."));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается удалить новость. Повторите попытку позже."
                    )
                );

                dispatch(NewsAction.deleteUpdateAdd.failed({params: {}, error}));
            }
        };
    }

    public static AddNews(file: File, image: File, newsData: INewsShort): IThunkAction {
        return async (dispatch) => {
            dispatch(NewsAction.deleteUpdateAdd.started({params: {}}));
            try {
                if (newsData.cityId === -1) {
                    newsData.cityId = undefined;
                }
                await requestsRepository.newsApiRequest.addNews(file, image, newsData);
                dispatch(NewsAction.deleteUpdateAdd.done({params: {}, result: {}}));

                dispatch(TooltipsAction.showTooltips("Новость успешно создана."));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается создать новость. Повторите попытку позже."
                    )
                );

                dispatch(NewsAction.deleteUpdateAdd.failed({params: {}, error}));
            }
        };
    }

    public static UpdateNews(id: string, file: File, image: File, newsData: INewsShort): IThunkAction {
        return async (dispatch) => {
            dispatch(NewsAction.deleteUpdateAdd.started({params: {}}));
            try {
                if (newsData.cityId === -1) {
                    newsData.cityId = undefined;
                }

                await requestsRepository.newsApiRequest.updateNews(id, file, image, newsData);
                dispatch(NewsAction.deleteUpdateAdd.done({params: {}, result: {}}));

                dispatch(TooltipsAction.showTooltips("Новость успешно обновлена"));
            } catch (error) {
                dispatch(
                    TooltipsAction.showTooltips(
                        "Что-то пошло не так. Не удается обновить новость. Повторите попытку позже."
                    )
                );

                dispatch(NewsAction.deleteUpdateAdd.failed({params: {}, error}));
            }
        };
    }

    public static InitialNewsById(id: string): IThunkAction {
        return async (dispatch) => {
            dispatch(NewsAction.getByFilter.started({params: {}}));
            try {
                const result = await requestsRepository.newsApiRequest.getById(id);
                dispatch(NewsAction.getByFilter.done({params: {}, result: {count: 1, items: [result]}}));
            } catch (error) {
                dispatch(TooltipsAction.showTooltips("Не удалось загрузить новость. Повторите попытку позже"));

                dispatch(NewsAction.getByFilter.failed({params: {}, error}));
            }
        };
    }

    public static Publicate(id: string, page: number): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.newsApiRequest.publish(id);
                dispatch(this.SortNews(page));
                dispatch(TooltipsAction.showTooltips("Новость опубликована."));
            } catch (error) {
                dispatch(TooltipsAction.showTooltips("Не удалось опубликовать новость. Повторите попытку позже"));
            }
        };
    }

    public static UnPublicate(id: string, page: number): IThunkAction {
        return async (dispatch) => {
            try {
                await requestsRepository.newsApiRequest.unpublished(id);
                dispatch(this.SortNews(page));
                dispatch(TooltipsAction.showTooltips("Новость снята с публикации."));
            } catch (error) {
                dispatch(TooltipsAction.showTooltips("Не удалось снять новость с публикации. Повторите попытку позже"));
            }
        };
    }
}
