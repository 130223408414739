import {reducerWithInitialState} from "typescript-fsa-reducers";
import {CalendarFilesActions, ICalendarFilesInitialParams} from "./calendarFilesAction";

interface ICalendarState {
    data: ICalendarFilesInitialParams[];
    error: boolean;
    loading: boolean;
}

const CalendarInitialState: ICalendarState = {
    data: [],
    error: false,
    loading: true
};

export const CalendarFilesReducer = reducerWithInitialState(CalendarInitialState)
    .case(CalendarFilesActions.initialFiles.started, (state) => ({
        data: state.data,
        error: false,
        loading: true
    }))
    .case(CalendarFilesActions.initialFiles.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false
    }))
    .case(CalendarFilesActions.initialFiles.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false
    }));
