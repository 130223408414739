import arrayMutators from "final-form-arrays";
import * as moment from "moment";
import React from "react";
import {Form, FormRenderProps} from "react-final-form";
import {connect} from "react-redux";
import {ICalendarDate} from "src/core/api/dto/CalendarDate.g";
import {ICalendarDateShort} from "src/core/api/dto/CalendarDateShort.g";
import {optionCategoryCalendar} from "src/core/api/dto/DayType.g";
import styled from "styled-components";
import {IconCross} from "../../shared/icons/IconCross";
import {BlueButton} from "../../ui/BlueButton";
import {ButtonGroup} from "../../ui/ButtonGroup";
import {RedButton} from "../../ui/RedButton";
import {InputFieldWrapper} from "../newsPage/component/inputFieldWrapper";
import {
    ArrayFormAddFormCalendar,
    required,
    validatePassAddFormCalendar,
    validatePreviewLineAddFormCalendar
} from "../newsPage/component/validation";
import {CalendarAddFormDispatch} from "./selectors";

interface IProps extends ReturnType<typeof CalendarAddFormDispatch> {
    visible: boolean;
    outsideClick: () => void;
    data?: ICalendarDate;
    date: moment.Moment;
}

interface IState {
    addNews: boolean;
}

class FormAddCelebrateConstructor extends React.Component<IProps, IState> {
    private initialValues: ICalendarDateShort;

    constructor(props: IProps) {
        super(props);
        this.state = {addNews: false};
    }

    public componentDidMount() {
        this.initialValues = this.props.data ? {...(this.props.data as ICalendarDate)} : ({} as ICalendarDate);
        this.forceUpdate();
        validatePassAddFormCalendar();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.data !== prevProps.data) {
            this.initialValues = this.props.data
                ? {
                      ...(this.props.data as ICalendarDate)
                  }
                : ({} as ICalendarDate);
            this.forceUpdate();
        }
    }

    public render() {
        const {visible} = this.props;
        return (
            <>
                <OutsideClick visible={visible} onClick={this.props.outsideClick} />
                <Container visible={visible}>
                    <CrossContainer onClick={this.props.outsideClick}>
                        <IconCross />
                    </CrossContainer>
                    <Title>{moment(this.props.date).format("DD MMMM YYYY")}</Title>
                    <Form
                        initialValues={this.initialValues}
                        onSubmit={this.onSubmit}
                        mutators={{...arrayMutators}}
                        render={this.renderForm}
                    />
                </Container>
            </>
        );
    }

    private renderForm = (props: FormRenderProps): React.ReactNode => {
        const {
            handleSubmit,
            form: {}
        } = props;

        return (
            <form onSubmit={handleSubmit} style={{marginBottom: 40}}>
                <InputFieldWrapper
                    arrayForm={ArrayFormAddFormCalendar}
                    name={"type"}
                    validate={required}
                    hasError={ArrayFormAddFormCalendar.type.showError}
                    type={"select"}
                    options={optionCategoryCalendar}
                />
                <InputFieldWrapper arrayForm={ArrayFormAddFormCalendar} name={"description"} type="textarea" />
                <ButtonGroup center={!(this.props.data && this.props.data.id)}>
                    <BlueButton type="submit" onClick={this.ShowError}>
                        {this.props.data && this.props.data.id ? "Изменить" : "Создать"}
                    </BlueButton>
                    {this.props.data && this.props.data.id ? (
                        <RedButton type="button" onClick={this.Delete}>
                            Удалить
                        </RedButton>
                    ) : (
                        <></>
                    )}
                </ButtonGroup>
            </form>
        );
    };

    // tslint:disable-next-line
    private onSubmit = async (value: any) => {
        const celebrate: ICalendarDateShort = {
            date: this.props.date.format(),
            description: value.description,
            type: value.type
        };
        if (this.props.data && this.props.data.id) {
            await this.props.update(this.props.data.id, celebrate);
        } else {
            await this.props.create(celebrate);
        }
        this.props.outsideClick();
        this.props.initialCalendar(this.props.date.month() + 1, this.props.date.year());
    };

    private ShowError = () => {
        validatePreviewLineAddFormCalendar();
    };

    private Delete = async () => {
        if (this.props.data) {
            await this.props.delete(this.props.data.id);
        }
        this.props.outsideClick();
        this.props.initialCalendar(this.props.date.month() + 1, this.props.date.year());
    };
}

export const FormAddCelebrate = connect(
    undefined,
    CalendarAddFormDispatch
)(FormAddCelebrateConstructor);

const Container = styled.div<{visible: boolean}>`
    display: ${(props) => (props.visible ? "flex" : "none")};
    position: fixed;
    flex-direction: column;
    background: #fff;
    z-index: 1;
    width: 445px;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 0 10px #f7f7f7;
    border: 1px solid #9ea7b0;
`;
export const OutsideClick = styled.div<{visible: boolean}>`
    display: ${(props) => (props.visible ? "block" : "none")};
    position: fixed;
    color: white;
    height: 100vh;
    width: 100%;
    background: transparent;
`;
const Title = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    color: #000000;
    margin-bottom: 50px;
`;
export const CrossContainer = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
`;
