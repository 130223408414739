import styled from "styled-components";

export const SmallWindow = styled<{active: boolean}, "div">("div")`
    position: absolute;
    z-index: 999;
    display: ${(props) => (props.active ? "flex" : "none")};
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    padding: 30px 78px;
    border: 1px solid #9ea7b0;
`;

export const SmallWindowTitle = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    color: #9ea7b0;
    margin-bottom: 50px;
`;

export const SmallWindowDescription = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #384552;
    margin-bottom: 70px;
`;
