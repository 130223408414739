import React from "react";

export const IconPen = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.6588 4.71559C21.1137 4.26066 21.1137 3.50243 20.6588 3.07082L17.9292 0.341203C17.4976 -0.113734 16.7393 -0.113734 16.2844 0.341203L14.138 2.47591L18.5124 6.8503L20.6588 4.71559ZM0 16.6256V21H4.37439L17.2759 8.08679L12.9015 3.7124L0 16.6256Z"
            fill="#1C6FFC"
        />
    </svg>
);
