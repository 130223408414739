import React from "react";

export const IconPaginationCalendar = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.9999 9.61111V12.3889H5.33321L12.9721 20.0278L10.9999 22L-0.000122282 11L10.9999 -1.05964e-07L12.9721 1.97222L5.33321 9.61111H21.9999Z"
            fill="#384552"
        />
    </svg>
);
